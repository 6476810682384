import { withUser } from 'next-firebase-auth'
import React, { createContext, useContext } from 'react'

import { ProfileSummaryType } from '~/endpoints/model'
import { useAuth } from '~/hooks/useAuth'
import { useMyProfile } from '~/hooks/useMyProfile'

export type CurrentAuthStateType = {
  userProfile?: ProfileSummaryType
  refetchUser?: () => void
  email?: string
  clientInitialized?: boolean
}

const AuthStateContext = createContext<CurrentAuthStateType>({
  userProfile: undefined,
  refetchUser: undefined,
  email: undefined,
  clientInitialized: undefined,
})

type Props = {
  children: React.ReactNode
}

const AuthStateProvider = ({ children }: Props) => {
  const { email, clientInitialized } = useAuth()
  const isAuthenticated = Boolean(email)

  const { data, refetch } = useMyProfile(isAuthenticated)
  const userData = data ?? undefined
  const authState: CurrentAuthStateType = {
    userProfile: userData,
    refetchUser: refetch,
    email: email ?? undefined,
    clientInitialized: clientInitialized ?? undefined,
  }
  return <AuthStateContext.Provider value={authState}>{children}</AuthStateContext.Provider>
}

export default withUser<Props>()(AuthStateProvider)

// Common current auth state context to be used by all components
export const useCurrentAuthState = () => {
  return useContext(AuthStateContext)
}
