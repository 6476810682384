import { Avatar, Badge, Box, Burger, Group, createStyles } from '@mantine/core'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { AuthEventsEnum } from '~/analytics/AuthEvent'
import { SubscriptionAllUpdates } from '~/components/home/SubscriptionUpdates'
import { MobileMenu } from '~/components/layout/MobileMenu'
import { AUTHENTICATED_MENU_ITEMS, UNAUTHENTICATED_MENU_ITEMS } from '~/components/layout/constants'
import { DropdownMenu } from '~/components/shared/DropdownMenu'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { getImageUrl } from '~/components/shared/image/helpers'
import { AccountSettings } from '~/components/user/AccountSettings'
import { useAnalytics } from '~/context/SegmentProvider'
import { ProfileSummaryType } from '~/endpoints/model/summaries'
import { useAuth } from '~/hooks/useAuth'
import { useScreenSize } from '~/hooks/useMobileScreen'
import type { TopMenuItem } from '~/utils/types'

const useStyle = createStyles(theme => ({
  burgerRoot: {
    borderRadius: 16,
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.appWhite,
  },
  burger: {
    height: '2px !important',
    '&::before, &::after': {
      height: '2px !important',
    },
  },
  input: {
    marginBottom: 20,
  },
}))

interface MainMenuProps {
  iconColor: string
  isAuthenticated: boolean
  profile?: ProfileSummaryType
  userEmail?: string | null
  onCreateNewGuide: () => Promise<void>
}

export const MainMenu: React.FC<MainMenuProps> = ({
  isAuthenticated,
  profile,
  onCreateNewGuide,
  userEmail,
}) => {
  const { classes } = useStyle()
  const { signOut } = useAuth()
  const [opened, setOpened] = useState(false)
  const { isSmallScreen } = useScreenSize()
  const router = useRouter()
  const MENU_ITEMS: TopMenuItem[] = isAuthenticated
    ? AUTHENTICATED_MENU_ITEMS(profile)
    : UNAUTHENTICATED_MENU_ITEMS

  const [notificationOpened, setNotificationOpened] = useState(false)
  const [accountSettingsOpened, setAccountSettingsOpened] = useState(false)
  const thatchSegment = useAnalytics()

  const handleClickMenuItem = async (item: TopMenuItem) => {
    switch (item.label) {
      case 'Create a Guide':
        await onCreateNewGuide()
        break
      case 'Log in':
      case 'Sign up':
        thatchSegment.trackAuthEvent(AuthEventsEnum.enum.SignUpStarted, {
          button_clicked: item.label == 'Log in' ? 'menu_log_in' : 'menu_sign_up',
          source_url: window.location.href,
        })
        await router.push(item.href ?? '/')
        break
      case 'Sign out':
        await signOut(() => {
          // navigate back to home page
          router.replace('/')
        })
        break
      case 'Notifications':
        // only launched on mobile
        setNotificationOpened(true)
        break
      case 'Account Settings':
        setAccountSettingsOpened(true)
        break
      default:
        // Ignore unknown labels.
        break
    }
  }

  const MenuBadge = (
    <Badge
      color="appBlack.0"
      variant="outline"
      px={4}
      py={16}
      h={40}
      w={78}
      sx={{ cursor: 'pointer' }}
    >
      <Group spacing={2}>
        <Burger
          data-testid="burger-menu"
          opened={opened}
          size={16}
          className={classes.burger}
          classNames={{ root: classes.burgerRoot, burger: classes.burger }}
        />
        <Avatar
          size={32}
          src={getImageUrl({ src: profile?.photo, width: 32 })}
          radius={32}
          imageProps={{ referrer: 'no-referrer' }} /// used for showing google signin profile image
        >
          <SvgIcon
            width={20}
            height={20}
            type={SvgIconType.NO_USER}
            fill="appWhite.0"
          />
        </Avatar>
      </Group>
    </Badge>
  )
  return (
    <>
      {isSmallScreen && notificationOpened && (
        <SubscriptionAllUpdates
          onClose={() => setNotificationOpened(false)}
          isOpened={notificationOpened}
          style={{ top: 0, zIndex: 1005 }}
        />
      )}
      {isAuthenticated && userEmail && accountSettingsOpened && (
        <AccountSettings
          onClose={() => setAccountSettingsOpened(false)}
          isOpened={accountSettingsOpened}
          style={{ top: 0, zIndex: 1006 }}
          userEmail={userEmail}
        />
      )}
      {isSmallScreen && opened && (
        <MobileMenu
          onClose={() => setOpened(false)}
          onSearch={() => setOpened(false)}
          isOpened={opened}
          isAuthenticated={isAuthenticated}
          profile={profile}
          handleClickItem={handleClickMenuItem}
        />
      )}
      {isSmallScreen ? (
        <Box onClick={() => setOpened(!opened)}>{MenuBadge}</Box>
      ) : (
        <DropdownMenu
          items={MENU_ITEMS}
          opened={opened}
          setOpened={setOpened}
          target={MenuBadge}
          handleClickItem={handleClickMenuItem}
        />
      )}
    </>
  )
}
