import { useQuery } from '@tanstack/react-query'

import { fetchAllPlaceSummary } from '~/endpoints/places'
import { ALL_PLACE_SUMMARY_QUERY_KEY, ONE_HOUR_IN_MILLI } from '~/utils/constants'

export const useFetchAllPlaces = (isDisabled = false) => {
  const {
    data: allPlaces = [],
    isLoading: isAllPlacesLoading,
    isError: isAllPlacesError,
  } = useQuery({
    queryKey: [ALL_PLACE_SUMMARY_QUERY_KEY],
    queryFn: () => fetchAllPlaceSummary(),
    gcTime: ONE_HOUR_IN_MILLI,
    staleTime: ONE_HOUR_IN_MILLI,
    enabled: !isDisabled,
  })

  return {
    allPlaces,
    isAllPlacesLoading,
    isAllPlacesError,
  }
}
