import { z } from 'zod'

import {
  BoardLinkBlock,
  DividerBlock,
  LinkBlock,
  ListBlock,
  PhotoBlock,
  PlaceBlock,
  TextBlock,
  VideoBlock,
  WeatherBlock,
} from '~/endpoints/model/board_nodes'
import { DateSchema } from '~/endpoints/model/common'
import { PlaceSummarySchema } from '~/endpoints/model/places'
import {
  BoardSummaryPageSchema,
  BoardSummarySchema,
  BoardSummarySliceSchema,
  ProfileSummarySchema,
} from '~/endpoints/model/summaries'

export const BoardSaleType = z.enum(['content', 'service'])

export const BoardNodeSchema = z.discriminatedUnion('type', [
  TextBlock,
  LinkBlock,
  PhotoBlock,
  PlaceBlock,
  ListBlock,
  DividerBlock,
  WeatherBlock,
  BoardLinkBlock,
  VideoBlock,
])

const BoardNodesSchema = BoardNodeSchema.array().default([])

export const BoardDetailsSchema = BoardSummarySchema.extend({
  blocks: z.array(z.string()).default([]),
  nodes: BoardNodesSchema,
  lockVersion: z.number(),
  saleType: BoardSaleType.nullable().default('content'),
  author: ProfileSummarySchema,
  sourceToken: z.string().nullish(),
})

const BoardDetailsRefsPlacesSchema = z.record(PlaceSummarySchema).nullish()
const BoardDetailsRefsBoardsSchema = z.record(BoardSummarySchema).nullish()
export const BoardDetailsRefsSchema = z.object({
  places: BoardDetailsRefsPlacesSchema,
  boards: BoardDetailsRefsBoardsSchema,
})

export const BoardDetailsWithRefsSchema = BoardDetailsSchema.extend({
  refs: BoardDetailsRefsSchema,
})

const EventTypeSchema = z.preprocess(arg => {
  if (typeof arg == 'string' && arg == 'create') {
    return 'published'
  }
  if (typeof arg == 'string' && arg == 'update') {
    return 'updated'
  }
}, z.string())

const BoardEventSchema = z.object({
  event: EventTypeSchema,
  timestamp: DateSchema,
  token: z.string(),
  author: z.string(),
  name: z.string().nullable(),
  image: z.string().nullable(),
  avatar: z.string().nullable(),
})

export const BoardEventsSchema = z.array(BoardEventSchema)

export type BoardEvent = z.infer<typeof BoardEventSchema>
export type BoardEventsData = z.infer<typeof BoardEventsSchema>

export type BoardDetails = z.infer<typeof BoardDetailsSchema>
export type BoardDetailsRefs = z.infer<typeof BoardDetailsRefsSchema>
export type BoardDetailsRefsPlacesType = z.infer<typeof BoardDetailsRefsPlacesSchema>
export type BoardDetailsWithRefs = z.infer<typeof BoardDetailsWithRefsSchema>
export type BoardNodeType = z.infer<typeof BoardNodeSchema>
export type BoardNodesType = z.infer<typeof BoardNodesSchema>

export type ExploreBoardsDataType = z.infer<typeof BoardSummarySliceSchema>
export type AllPublishedBoardsData = z.infer<typeof BoardSummaryPageSchema>
export type AllSavedByUserBoardsData = z.infer<typeof BoardSummaryPageSchema>

export type TextBlockType = z.infer<typeof TextBlock>
export type LinkBlockType = z.infer<typeof LinkBlock>
export type PhotoBlockType = z.infer<typeof PhotoBlock>
export type PlaceBlockType = z.infer<typeof PlaceBlock>
export type ListBlockType = z.infer<typeof ListBlock>
export type DividerBlockType = z.infer<typeof DividerBlock>
export type WeatherBlockType = z.infer<typeof WeatherBlock>
export type BoardLinkBlockType = z.infer<typeof BoardLinkBlock>
export type VideoBlockType = z.infer<typeof VideoBlock>
export type BoardSale = z.infer<typeof BoardSaleType>
