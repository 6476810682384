import { z } from 'zod'

export const DateSchema = z.preprocess(arg => {
  if (typeof arg == 'string' || arg instanceof Date) {
    return new Date(arg).toUTCString()
  }
}, z.string())

const Sort = z.object({
  orderBy: z.array(z.string()).default([]),
})

const Pageable = z.object({
  number: z.number(),
  size: z.number(),
  sort: Sort,
})

export const GenericSlice = z.object({
  empty: z.boolean(),
  numberOfElements: z.number(),
  offset: z.number(),
  pageNumber: z.number(),
  pageable: Pageable,
  size: z.number(),
})

export const GenericPage = GenericSlice.extend({
  totalPages: z.number().nullish(),
  totalSize: z.number().nullish(),
})

export type GenericPageType = z.infer<typeof GenericPage>
