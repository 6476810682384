import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { fetchMyProfile, patchProfile } from '~/endpoints/user'
import { MY_PROFILE_QUERY_KEY, TEN_MINUTES_IN_MILLI } from '~/utils/constants'

export function useMyProfile(isAuthenticated: boolean) {
  return useQuery({
    queryKey: [MY_PROFILE_QUERY_KEY],
    queryFn: () => fetchMyProfile(),
    enabled: isAuthenticated,
    staleTime: TEN_MINUTES_IN_MILLI,
    gcTime: TEN_MINUTES_IN_MILLI,
  })
}

export function useMutateMyProfile() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (updates: Record<string, unknown>) => {
      return patchProfile(updates)
    },
    onSuccess: profile => {
      queryClient.setQueryData([MY_PROFILE_QUERY_KEY], profile)
    },
  })
}
