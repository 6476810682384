/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from 'react'

import ThatchSegment from '~/analytics/thatchSegment'

const ThatchSegmentContext = React.createContext<ThatchSegment>(undefined!)

type Props = {
  children: React.ReactNode
}

export const SegmentProvider = ({ children }: Props) => {
  const thatchSegment = useMemo(() => new ThatchSegment(), [])
  return (
    <ThatchSegmentContext.Provider value={thatchSegment}>{children}</ThatchSegmentContext.Provider>
  )
}

// Create an thatch segment analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(ThatchSegmentContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}
