import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'

import { BoardDetails, BoardSaleType } from '~/endpoints/model/board'
import { DiscountEnumType, DiscountTypeSchema } from '~/endpoints/model/discounts'
import { Question, ServiceType } from '~/endpoints/model/summaries'
import { calculateDiscountedPrice } from '~/utils/helper'

export const PaymentTypeSchema = z.enum(['TIP', 'PAYMENT'])
export type PaymentType = z.infer<typeof PaymentTypeSchema>

const StripeSessionDetailsSchema = z.object({
  transactionType: PaymentTypeSchema,
  sellableTitle: z.string().nullish().default('Untitled'),
  authorName: z.string(),
  authorUid: z.string(),
  authorStripeConnectedId: z.string().nullish(),
  customerTipMessage: z.string().nullable().default(''),
  clientReferenceId: z.string(),
  contentToken: z.string(),
  contentType: z.string().default('Board'),
  imageURL: z.string().nullish(),
  txId: z.string(),
  amount: z.number().nullish(),
  successURL: z.string(),
  cancelURL: z.string(),
  saleType: BoardSaleType,
  discountId: z.number().nullish(),
  discountCode: z.string().nullish(),
  discountType: DiscountTypeSchema.nullish(),
})

export type StripeSessionDetails = z.infer<typeof StripeSessionDetailsSchema>

export const buildStripeSessionPayload = ({
  boardDetails,
  paymentType,
  coverImage,
  currentUserId,
  message,
  amount,
  discountCode,
  discountId,
  discountType,
  discountAmount,
}: {
  boardDetails: BoardDetails
  paymentType: PaymentType
  coverImage: string | null
  currentUserId?: string | null
  message?: string
  amount?: number
  discountId?: number | null
  discountCode?: string | null
  discountType?: DiscountEnumType
  discountAmount?: number | null
}) => {
  const {
    name,
    author: { uid: authorUid, stripeSellerId, name: authorName },
    token,
    saleType,
    discount,
    price,
  } = boardDetails

  let discountedPrice = price
  if (discountId && discountAmount && price) {
    // handle promo discount first
    discountedPrice = calculateDiscountedPrice(price, discountAmount).raw
  } else if (discount && price) {
    // handle sale discount
    discountedPrice = calculateDiscountedPrice(price, discount.amount).raw
  }

  const txId = uuidv4()
  const currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
  const successUrl = `${currentUrl}?thatchstatus=success&type=${paymentType}&txid=${txId}&token=${token}&sessionid={CHECKOUT_SESSION_ID}`
  const cancelUrl = `${currentUrl}?thatchstatus=cancelled&type=${paymentType}&txid=${txId}&token=${token}&sessionid={CHECKOUT_SESSION_ID}`

  const payload: StripeSessionDetails = {
    transactionType: paymentType,
    sellableTitle: name,
    authorName: authorName,
    authorUid: authorUid,
    authorStripeConnectedId: stripeSellerId,
    contentToken: token,
    imageURL:
      coverImage ??
      'https://uploads-ssl.webflow.com/610acfb4aed44c21ee2e736f/61296459e31039da27c688bf_thatch_link_preview.png',
    amount: paymentType == 'TIP' ? amount : discountedPrice,
    saleType: saleType ?? 'content',
    successURL: successUrl,
    cancelURL: cancelUrl,
    txId: txId,
    customerTipMessage: message ?? '',
    clientReferenceId: `${currentUserId}-${token}`,
    contentType: 'Board',
    discountCode,
    discountId,
    discountType,
  }

  return payload
}

export const StripeSessionSchema = z.object({
  id: z.string(),
  url: z.string(),
  expires_at: z.number(),
})

export type StripeSession = z.infer<typeof StripeSessionSchema>

export type QuestionAndAnswer = Question & {
  answer: string
}

export type CreateServiceSessionRequest = {
  txId: string
  seller: string
  service: number
  type: ServiceType
  tier: 'base' | 'standard' | 'premium'
  amount: number
  fee?: number
  description: string
  image: string
  successURL: string
  cancelURL: string
  resourceId?: string
  locationId?: string
  bookingId?: string
  date?: string
  time?: number
  timeLabel?: string
  duration?: number
  delivery?: number
  recs?: number
  questions?: QuestionAndAnswer[]
}
