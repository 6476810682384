import { z } from 'zod'

export type WeatherLocation = {
  name: string
  lat: number
  long: number
}

const WeatherInfoSchema = z.object({
  id: z.number(),
  main: z.string(),
  description: z.string(),
  icon: z.string(),
})

const DailyWeatherSchema = z.object({
  dt: z.number(),
  temp: z.object({
    day: z.number(),
    min: z.number(),
    max: z.number(),
    night: z.number(),
    eve: z.number(),
    morn: z.number(),
  }),
  feels_like: z.object({
    day: z.number(),
    night: z.number(),
    eve: z.number(),
    morn: z.number(),
  }),
  weather: z.array(WeatherInfoSchema),
  clouds: z.number(),
})

const CurrentWeatherSchema = z.object({
  dt: z.number(),
  feels_like: z.number(),
  clouds: z.number(),
  sunrise: z.number(),
  sunset: z.number(),
  temp: z.number(),
  weather: z.array(WeatherInfoSchema),
})

export const OpenWeatherApiResponseSchema = z.object({
  lat: z.number(),
  lon: z.number(),
  timezone: z.string(),
  current: CurrentWeatherSchema,
  daily: z.array(DailyWeatherSchema),
})

export type Weather = z.infer<typeof OpenWeatherApiResponseSchema>

//Utils
export const mapStringToWeatherCondition = (input: string, cloudiness: number) => {
  switch (input) {
    case 'Thunderstorm':
      return 'thunderstorm'
    case 'Drizzle':
      return 'drizzle'
    case 'Rain':
      return 'rain'
    case 'Snow':
      return 'snow'
    case 'Clear':
      return 'clear'
    case 'Clouds':
      return cloudiness >= 85 ? 'heavycloud' : 'lightcloud'
    case 'Mist':
      return 'mist'
    case 'fog':
      return 'fog'
    case 'Smoke':
    case 'Haze':
    case 'Dust':
    case 'Sand':
    case 'Ash':
    case 'Squall':
    case 'Tornado':
      return 'atmosphere'
    default:
      return 'unknown'
  }
}

export const getCurrentTime = (weather: Weather) => {
  const options = {
    month: 'long',
    day: '2-digit',
    hour: 'numeric',
    timeZone: weather.timezone,
    hourCycle: 'h11',
    minute: 'numeric',
    second: 'numeric',
  } as const
  const date = new Date(weather.current.dt * 1000)
  const formatter = new Intl.DateTimeFormat('default', options)

  return formatter.format(date)
}

export const formatTemperature = (weather: Weather) => {
  return Math.round(weather.current.temp).toString()
}

export const isDayTime = (weather: Weather) => {
  const currentWeather = weather.current
  const date = new Date(currentWeather.dt * 1000)
  const rise = new Date(currentWeather.sunrise * 1000)
  const set = new Date(currentWeather.sunset * 1000)
  return date > rise && date < set
}

export const capitalizeFirstLetter = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

export const highLowTempString = (weather: Weather) => {
  const currentDaily = weather.daily[0]
  const high = Math.round(currentDaily.temp.max).toString()
  const low = Math.round(currentDaily.temp.min).toString()
  return `H: ${high}°  L: ${low}°`
}

export const forecastList = (weather: Weather) => {
  return weather.daily.slice(1, 6)
}

export const getDayName = (dt: number) => {
  const date = new Date(dt * 1000)
  const formatter = new Intl.DateTimeFormat('default', { weekday: 'short' })
  return formatter.format(date)
}
