import { Box, Flex, Group, Stack, createStyles } from '@mantine/core'

import { FOOTER_LINKS } from '~/components/layout/constants'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { LocalImage } from '~/components/shared/image/LocalImage'
import { Typography } from '~/components/shared/text/Typography'

import appleLogo from 'public/app_store_icon.webp'
import lightLogo from 'public/images/logo-light.png'
import googleLogo from 'public/play_store_icon.webp'

interface FooterProps {
  isCreator: boolean
}

const useStyle = createStyles(theme => ({
  link: {
    color: 'white',
  },
  image: {
    objectFit: 'contain',
  },
  container: {
    backgroundColor: theme.colors.appBlack,
    padding: '80px 88px 56px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: '40px 36px',
    },
  },
}))

const LinkItem = ({
  to,
  label,
  isOpenInNewWindow,
  enablePrefetch,
}: {
  to: string
  label: string
  isOpenInNewWindow: boolean
  enablePrefetch: boolean
}) => {
  const { classes } = useStyle()
  return (
    <Group spacing={8}>
      <ThatchLink
        to={to}
        className={classes.link}
        isOpenInNewWindow={isOpenInNewWindow}
        prefetch={enablePrefetch}
      >
        <Typography variant="caption">{label}</Typography>
      </ThatchLink>
    </Group>
  )
}

export const Footer: React.FC<FooterProps> = ({ isCreator }) => {
  const { classes } = useStyle()
  return (
    <Box className={classes.container}>
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        justify="space-between"
        mb={100}
        gap={{ base: 40, sm: 0 }}
      >
        <Stack>
          <LocalImage
            src={lightLogo}
            height={43}
            width={145}
            alt="logo"
          />
          <Typography
            mt={15}
            variant="body1"
            color="appWhite.0"
          >
            Thatch is where
            <br />
            great trips are made
          </Typography>
        </Stack>
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          align="flex-start"
          justify="flex-end"
          gap={{ base: 40, sm: 160 }}
        >
          {FOOTER_LINKS.map(({ title, links }) => (
            <Stack
              key={title}
              align="flex-start"
            >
              <Typography
                variant="body2"
                color="appWhite.0"
              >
                {title}
              </Typography>
              {links
                .filter(e => (isCreator ? e.label != 'Start Selling' : true))
                .map(({ label, to, icon, openInNewWindow, enablePrefetch }) => (
                  <LinkItem
                    key={to}
                    label={label}
                    to={to}
                    isOpenInNewWindow={openInNewWindow}
                    enablePrefetch={enablePrefetch}
                  />
                ))}
            </Stack>
          ))}
        </Flex>
      </Flex>

      <Flex
        direction={{ base: 'column', sm: 'row' }}
        justify="space-between"
        align={{ base: 'flex-start', sm: 'center' }}
        gap={{ base: 16, sm: 0 }}
      >
        <Group spacing={16}>
          <ThatchLink
            to="https://apps.apple.com/us/app/thatch-travel-plan-share/id1524536119"
            className={classes.link}
            isOpenInNewWindow={true}
          >
            <LocalImage
              className={classes.image}
              src={appleLogo}
              height={40}
              width={133}
            />
          </ThatchLink>
          <ThatchLink
            to="https://play.google.com/store/apps/details?id=co.thatch.apps.hub&hl=en_US&gl=US&utm_source=thatch-web"
            className={classes.link}
            isOpenInNewWindow={true}
          >
            <LocalImage
              className={classes.image}
              src={googleLogo}
              height={40}
              width={133}
            />
          </ThatchLink>
        </Group>
        <Typography
          variant="caption"
          color="appWhite.0"
        >
          © 2024 Thatch. All rights reserved.
        </Typography>
      </Flex>
    </Box>
  )
}
