import { Text } from '@mantine/core'
import Link from 'next/link'

interface UnderlineLinkProps {
  href: string
  label: string
}
export const UnderlineLink: React.FC<UnderlineLinkProps> = ({ href, label }) => {
  return (
    <Link href={href}>
      <Text
        component="span"
        td="underline"
      >
        {label}
      </Text>
    </Link>
  )
}
