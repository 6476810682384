import { AnalyticsBrowser, Context, ContextCancelation } from '@segment/analytics-next'

import { CreatorEvent, CreatorEventPayload } from '~/analytics/CreatorEvent'
import { ExploreEventPayload, ExploreEventsType } from '~/analytics/ExploreEvent'
import { MapEventsType } from '~/analytics/MapEvent'
import { PaywallEventPayload, PaywallEventsType } from '~/analytics/PaywallEvent'
import { AddAPlaceEventPayload, PlaceEventPayload, PlaceEventsType } from '~/analytics/PlaceEvent'
import { ProfileEvent, ProfileEventPayload } from '~/analytics/ProfileEvent'

import { AuthEventsType, AuthViewEventPayload } from './AuthEvent'
import {
  BoardCommonEventPayload,
  BoardEventsType,
  BoardSharedEventPayload,
  PersonalizeEventPayload,
} from './BoardEvent'

/**
 * Workaround to disable segment for development env
 * More here:
 * https://github.com/segmentio/analytics-next/issues/558
 * https://github.com/segmentio/analytics-next/issues/383
 */
class TogglePlugin {
  name: string

  type: 'before' | 'after' | 'destination' | 'enrichment' | 'utility'

  version: string

  ajsDisabled: boolean

  constructor({ disableAjs }: { disableAjs: boolean }) {
    this.name = 'Toggle Event Delivery Plugin'
    this.type = 'before' // Only `before` plugins can prevent event delivery
    this.version = '1.0.0'
    this.ajsDisabled = disableAjs
  }

  load() {
    return Promise.resolve()
  }

  isLoaded() {
    return true
  }

  gateKeep(ctx: Context) {
    if (!this.ajsDisabled) {
      return ctx
    }
    ctx.cancel(
      new ContextCancelation({
        reason: 'AJS Disabled for dev env',
        retry: false,
      })
    )
  }

  alias(ctx: Context) {
    return this.gateKeep(ctx)
  }

  identify(ctx: Context) {
    return this.gateKeep(ctx)
  }

  group(ctx: Context) {
    return this.gateKeep(ctx)
  }

  page(ctx: Context) {
    return this.gateKeep(ctx)
  }

  track(ctx: Context) {
    return this.gateKeep(ctx)
  }
}

export default class ThatchSegment {
  _segment: AnalyticsBrowser

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const writeKey = process.env.NEXT_PUBLIC_SEGMENT_KEY!
    const segment = AnalyticsBrowser.load({
      writeKey,
      plugins: [
        new TogglePlugin({
          disableAjs: process.env.NEXT_PUBLIC_THATCH_ENV == 'development',
        }),
      ],
    })
    this._segment = segment
  }

  private _commonTrackEvent(eventName: string, props: object) {
    this._segment.track(eventName, props)
  }

  async getCurrentUser() {
    const user = await this._segment.user()
    return user
  }

  identitfyUser(params: { userId: string; email: string | null }): void {
    this._segment.identify(params.userId, {
      email: params.email,
    })
  }

  pageTrack(url: string, properties?: object) {
    this._segment.page(url, properties)
  }

  trackPaywallEvent(eventName: PaywallEventsType, payload: PaywallEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackPaywallResultEvent(eventName: PaywallEventsType, payload: object) {
    this._commonTrackEvent(eventName, payload)
  }

  trackPaywallInlineUserSetup(eventName: PaywallEventsType, boardToken: string) {
    this._commonTrackEvent(eventName, { boardToken: boardToken })
  }

  trackProfileEvent(eventName: ProfileEvent, payload: ProfileEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackExploreEvent(eventName: ExploreEventsType, payload: ExploreEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackMapEvent(eventName: MapEventsType, payload: object) {
    this._commonTrackEvent(eventName, payload)
  }

  trackMiscEvent(eventName: string, payload: object) {
    this._commonTrackEvent(eventName, payload)
  }

  trackPlaceEvent(eventName: PlaceEventsType, payload: PlaceEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackAddAPlaceEvent(eventName: PlaceEventsType, payload: AddAPlaceEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackBoardPersonalizeEvent(eventName: BoardEventsType, payload: PersonalizeEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackBoardShareEvent(eventName: BoardEventsType, payload: BoardSharedEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackBoardCommonEvent(eventName: BoardEventsType, payload: BoardCommonEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackAuthEvent(eventName: AuthEventsType, payload: AuthViewEventPayload) {
    this._commonTrackEvent(eventName, payload)
  }

  trackCreatorEvent(event: CreatorEvent, payload: CreatorEventPayload) {
    this._commonTrackEvent(event, payload)
  }

  reset() {
    this._segment.reset()
  }
}
