import * as Sentry from '@sentry/nextjs'
import React, { ErrorInfo, ReactNode } from 'react'

import { ThatchError } from '~/components/error/ThatchError'
import { isDevMode } from '~/utils/constants'
import { captureSentryException, showSentryReportDialog } from '~/utils/sentry'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error?: Error
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, error: undefined }
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
    Sentry.withScope(scope => {
      scope.setTransactionName(`Client side error`)
      scope.setExtra('stack', errorInfo.componentStack)
      const eventId = captureSentryException(error)
      showSentryReportDialog({ eventId })
    })
  }

  render() {
    if (this.state.hasError && !isDevMode) {
      return (
        <ThatchError
          message="Something went wrong"
          isErrorBoundary={true}
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
