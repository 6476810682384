import {
  Box,
  Flex,
  Group,
  Menu,
  MenuProps,
  MenuStylesNames,
  Styles,
  createStyles,
} from '@mantine/core'
import { Dispatch, ReactNode, SetStateAction, useCallback } from 'react'

import { MantineNextLink } from '~/components/shared/MantineNextLink'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { Typography } from '~/components/shared/text/Typography'
import type { TopMenuItem } from '~/utils/types'

const useStyle = createStyles(theme => ({
  dropdown: {
    borderRadius: 0,
    padding: 16,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: '100% !important',
      height: '100vh',
      marginTop: 14,
    },
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '150%',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 24,
    },
  },
  menuActionItem: {
    '&:hover': {
      background: 'none',
    },
    padding: 8,
  },
  menuLabel: {
    textTransform: 'uppercase',
    color: theme.colors.appBlack,
    fontSize: 14,
    marginBottom: 20,
  },
  divider: {
    margin: '8px 8px',
    borderColor: theme.colors.appPlaceholder,
  },
  item: {
    '&[data-hovered]': {
      backgroundColor: theme.colors.appYellow,
      textDecoration: 'none',
    },
  },
}))

interface DropdownMenuProps extends MenuProps {
  items: TopMenuItem[]
  target: ReactNode
  listTopElement?: ReactNode
  setOpened?: Dispatch<SetStateAction<boolean>>
  handleClickItem: (item: TopMenuItem) => void
  styles?: Styles<MenuStylesNames>
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  styles,
  target,
  listTopElement,
  setOpened,
  handleClickItem,
  ...rest
}) => {
  const { classes } = useStyle()
  const renderItem = useCallback(
    (item: TopMenuItem, index: number) => {
      switch (item.type) {
        case 'divider':
          return (
            <Menu.Divider
              key={`${item.type}-${index}`}
              color={item.color}
              className={classes.divider}
            />
          )
        case 'label':
          return (
            <Menu.Label
              key={`${item.label?.toString()}-${index}`}
              color={item.color}
              className={classes.menuLabel}
            >
              {item.label}
            </Menu.Label>
          )
        case 'link':
          return (
            <Menu.Item
              key={`${item.label?.toString()}-${index}`}
              component={MantineNextLink}
              passHref
              href={item.href || ''}
              target={item.linkTarget}
              className={classes.menuItem}
              color={item.color}
            >
              <Flex
                align="center"
                gap={6}
              >
                {item.customNode ? (
                  item.customNode
                ) : (
                  <Group py={4}>
                    {item.icon}
                    {item.label}
                  </Group>
                )}
                {item.isNew && (
                  <Flex
                    bg="appFuscia.0"
                    align="center"
                    justify="center"
                    sx={{ width: 41, height: 23, borderRadius: '27px' }}
                  >
                    <Typography
                      variant="tag"
                      color="appWhite.0"
                    >
                      New
                    </Typography>
                  </Flex>
                )}
              </Flex>
            </Menu.Item>
          )
        case 'actionButton':
          return (
            <Menu.Item
              key={`${item.label?.toString()}-${index}`}
              component={Box}
              className={classes.menuActionItem}
              color={item.color}
            >
              <ThatchButton
                fullWidth
                loading={item.loading}
                color={`${item.isPrimary ? 'appBlack.0' : 'appWhite.0'}`}
                sx={{ border: `1px solid black` }}
                onClick={() => handleClickItem(item)}
              >
                <Typography
                  variant="caption"
                  color={`${item.isPrimary ? 'appWhite.0' : 'appBlack.0'}`}
                >
                  {item.label}
                </Typography>
              </ThatchButton>
            </Menu.Item>
          )
        case 'action':
          return (
            <Menu.Item
              key={`${item.label?.toString()}-${index}`}
              className={classes.menuItem}
              color={item.color}
              onClick={() => handleClickItem(item)}
            >
              {item.customNode ? (
                item.customNode
              ) : (
                <Group py={4}>
                  {item.icon}
                  {item.label}
                </Group>
              )}
            </Menu.Item>
          )
        default:
          // Ignore unknown item type.
          return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClickItem]
  )

  return (
    <Menu
      shadow="md"
      width={320}
      position="bottom-end"
      onChange={isOpen => setOpened && setOpened(isOpen)}
      classNames={
        rest.classNames || {
          item: classes.item,
          dropdown: classes.dropdown,
        }
      }
      styles={styles}
      {...rest}
    >
      <Menu.Target>{target}</Menu.Target>
      <Menu.Dropdown>
        {listTopElement}
        {items.map(renderItem)}
      </Menu.Dropdown>
    </Menu>
  )
}
