import { z } from 'zod'

import { captureSentryException } from '~/utils/sentry'

/**
 * Common zod parser for capturing and logging parse errors
 * https://github.com/colinhacks/zod/issues/105
 */
export const parseFactory =
  <T extends z.ZodTypeAny>(schema: T, name: string, skipLog?: boolean) =>
  (data: unknown): z.infer<T> => {
    if (!skipLog) {
      console.debug(`Parsing schema ${name}`)
    }
    const result = schema.safeParse(data)
    if (result.success) {
      return result.data
    } else {
      const error = result.error

      console.error(error.message)
      error.errors.forEach(e => {
        switch (e.code) {
          case 'invalid_type':
            console.error(
              'invalid type found in %s\n  path %s\n  expected: %s\n  received: %s',
              name,
              e.path.join('.'),
              e.expected,
              e.received
            )
            break

          case 'invalid_literal':
            console.error('invalid literal at path "%s": ', e.path.join('.'))
            break

          case 'unrecognized_keys':
          case 'invalid_union':
          case 'invalid_union_discriminator':
          case 'invalid_enum_value':
          case 'invalid_arguments':
          case 'invalid_return_type':
          case 'invalid_date':
          case 'invalid_string':
          case 'too_small':
          case 'too_big':
          case 'invalid_intersection_types':
          case 'not_multiple_of':
          case 'not_finite':
          case 'custom':
            console.error(e)
            break
        }
      })

      captureSentryException(result.error.format())
      throw result.error
    }
  }
