import { useEffect, useState } from 'react'

import { AutoPredictions } from '~/components/shared/googlemaps/types'
import { fetchPlacePredictions } from '~/endpoints/places'
import { sessionToken } from '~/utils/sessionToken'

export const useGetPlacePredictions = (
  debouncedQuery: string,
  locationbias: string | undefined,
  isItemSubmitted?: boolean,
  // isItemSubmitted is to avoid useEffect call when an item is selected
  placeTypes?: string
) => {
  const [placePredictions, setPlacePredictions] = useState<AutoPredictions[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const getPlacePredictions = async () => {
      setIsLoading(true)
      const response = await fetchPlacePredictions(
        debouncedQuery,
        locationbias,
        sessionToken,
        placeTypes
      )
      setPlacePredictions(response)
      setIsLoading(false)
    }

    if (debouncedQuery && !isItemSubmitted) {
      getPlacePredictions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery])

  return { placePredictions, isLoading }
}
