import * as Sentry from '@sentry/nextjs'
import { Hub, ReportDialogOptions } from '@sentry/nextjs'
import type { CaptureContext } from '@sentry/types'

export const isSentryEnabled =
  process.env.NEXT_PUBLIC_THATCH_ENV && process.env.NEXT_PUBLIC_THATCH_ENV !== 'development'

export function captureSentryException(
  exception: unknown,
  context?: CaptureContext
): ReturnType<typeof Sentry.captureException> {
  if (isSentryEnabled) {
    return Sentry.captureException(exception, context)
  }

  return ''
}

export function showSentryReportDialog(options?: ReportDialogOptions, hub?: Hub) {
  if (isSentryEnabled) {
    Sentry.showReportDialog(options, hub)
  }
}
