import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import { useCurrentAuthState } from '~/context'

export const SentryUserManager = () => {
  const { userProfile: userData, email } = useCurrentAuthState()
  useEffect(() => {
    if (email) {
      Sentry.setUser({
        email: email ?? undefined,
        username: userData?.name ?? undefined,
        id: userData?.uid ?? undefined,
      })
    }
  }, [email, userData])
  return null
}
