import { z } from 'zod'

const BoardTextType = z.enum([
  'heading1',
  'heading2',
  'heading3',
  'body1',
  'body2',
  'caption',
  'overline',
])

export const BoardBlock = z.object({
  id: z.number().optional(),
  uid: z.string().optional(),
  lockVersion: z.number().nullish(),
  board: z.number().optional(),
  version: z.number().optional(),
})

export const TextBlock = BoardBlock.extend({
  text: z.string(),
  style: BoardTextType,
  type: z.literal('text'),
})

export const LinkBlock = BoardBlock.extend({
  title: z.string().nullish(),
  link: z.string(),
  icon: z.string().nullish(),
  type: z.literal('link'),
})

export const PhotoBlock = BoardBlock.extend({
  title: z.string().nullish(),
  description: z.string().nullish(),
  url: z.string(),
  type: z.literal('photo'),
})

export const PlaceBlock = BoardBlock.extend({
  place: z.number(),
  type: z.literal('place'),
})

export const ListBlock = BoardBlock.extend({
  list: z.number(),
  type: z.literal('list'),
})

export const DividerBlock = BoardBlock.extend({
  type: z.literal('divider'),
})

export const WeatherBlock = BoardBlock.extend({
  lat: z.number(),
  long: z.number(),
  name: z.string(),
  type: z.literal('weather'),
})

export const BoardLinkBlock = BoardBlock.extend({
  type: z.literal('board-link'),
  token: z.string(),
})

export const VideoBlock = BoardBlock.extend({
  url: z.string(),
  aspectRatio: z.number(), // this is required for mobile app to work correctly
  type: z.literal('video'),
})
