import { z } from 'zod'

import { DateSchema } from '~/endpoints/model/common'
import { ProfileDetailSchema } from '~/endpoints/model/summaries'

export const UserDetailsSchema = z.object({
  id: z.number(),
  uid: z.string(),
  name: z.string(),
  username: z.string(),
  firstName: z.string(),
  photo: z.string().nullable(),
  cover: z.string().nullable(),
  website: z.string().nullable(),
  instagram: z.string().nullable(),
  tiktok: z.string().nullable(),
  youtube: z.string().nullable(),
  bio: z.string().nullable(),
  shopEnabled: z.boolean(),
  destination: z.boolean(),
  stripeSellerId: z.string().nullable(),
  updated: DateSchema.nullable(),
  created: DateSchema,
  onboarding: z.record(z.string(), z.boolean()).nullish(),
})

export type UserDetails = z.infer<typeof UserDetailsSchema>

export const UserSummarySchema = UserDetailsSchema.extend({
  photo: z.string().nullish(),
  cover: z.string().nullish(),
  website: z.string().nullish(),
  instagram: z.string().nullish(),
  tiktok: z.string().nullish(),
  youtube: z.string().nullish(),
  bio: z.string().nullish(),
  shopEnabled: z.boolean().nullish(),
  destination: z.boolean().nullish(),
  stripeSellerId: z.string().nullish(),
  deleted: z.boolean().nullish(),
})

export const CurrentUserViewSchema = z.object({
  isFollower: z.boolean(),
  isMine: z.boolean(),
})

export const ResigterUserSchema = z.object({
  email: z.string(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  photo: z.string(),
})

export const CreateUserSchema = ResigterUserSchema.extend({
  uid: z.string().nullish(),
  token: z.string(),
  salt: z.string().nullish(),
})

export type ProfileDetail = z.infer<typeof ProfileDetailSchema>

export type UserSummary = z.infer<typeof UserSummarySchema>

export type RegisterUser = z.infer<typeof ResigterUserSchema>

export type CreateUser = z.infer<typeof CreateUserSchema>

const noPasswordUserSchema = CreateUserSchema.omit({ password: true })
export type CreateUserPayload = z.TypeOf<typeof noPasswordUserSchema>
