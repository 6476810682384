import { useMutation, useQueryClient } from '@tanstack/react-query'

import { PlaceSummaryType } from '~/endpoints/model/places'
import { deletePlace } from '~/endpoints/places'
import { ALL_PLACE_SUMMARY_QUERY_KEY } from '~/utils/constants'

export const useDeletePlace = (placeId: number | undefined | null) => {
  const queryClient = useQueryClient()

  const { isPending: isDeletePlaceLoading, mutateAsync: mutateDeletePlace } = useMutation({
    mutationFn: (currentPlaceId: number) => deletePlace(currentPlaceId),
    onSuccess: () => {
      queryClient.setQueryData(
        [ALL_PLACE_SUMMARY_QUERY_KEY],
        (oldData: PlaceSummaryType[] | undefined) => {
          if (oldData) {
            const newData = [...oldData]
            const deletedPlaceIndex = newData.findIndex(item => item.id === placeId)
            if (deletedPlaceIndex > -1) {
              newData.splice(deletedPlaceIndex, 1)
            }
            return [...newData]
          }
        }
      )
    },
  })

  return { mutateDeletePlace, isDeletePlaceLoading }
}
