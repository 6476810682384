import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateAllPlacesCache } from '~/components/places/utils'
import { PlaceDetailsType, PlaceSummaryType } from '~/endpoints/model/places'
import { updatePlace } from '~/endpoints/places'

export const useUpdatePlace = () => {
  const queryClient = useQueryClient()

  const { isPending: isUpdatePlaceLoading, mutateAsync: mutateUpdatePlace } = useMutation({
    mutationFn: ({
      currentPlaceId,
      payload,
    }: {
      currentPlaceId: number
      payload: PlaceSummaryType
    }) => updatePlace(currentPlaceId, payload),
    onSuccess: (placeDetails: PlaceDetailsType) => updateAllPlacesCache(queryClient, placeDetails),
  })

  return { mutateUpdatePlace, isUpdatePlaceLoading }
}
