import { ImageLoaderProps } from 'next/image'

import config from '~/thatch-config'

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src
}

const isCDNSupported = (path: string) => {
  const extension = path.split('.').pop()?.toLowerCase()
  switch (extension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return true

    default:
      return false
  }
}

export const isAbsoluteImageUrl = (src?: string) => {
  if (src) {
    return src.startsWith('http') || src.startsWith('/api/proxy/maps/place/photo')
  }
  return false
}

export const isUnSplashImageUrl = (src: string) => {
  return src.startsWith('https://images.unsplash.com')
}

export const isValidImageUrl = (src?: string | null) => {
  return Boolean(src !== undefined && src !== null && src)
}

export const cloudflareLoader = ({ src, width, quality }: ImageLoaderProps) => {
  let base = config.cdnDisabled ? config.imageEndpoint : config.cdnEndpoint ?? config.imageEndpoint
  if (!config.cdnDisabled && isCDNSupported(src)) {
    const params = [`width=${width}`]
    if (quality) {
      params.push(`quality=${quality}`)
    }
    params.push(`format=webp`)
    const paramsString = params.join(',')
    base += `/cdn-cgi/image/${paramsString}`
  }
  return `${base}/images/${normalizeSrc(src)}`
}

export const getImageUrl = ({
  src,
  width = 1600,
  quality,
}: {
  src?: string | null
  width?: number
  quality?: number
}) => {
  if (src && src.length > 0) {
    if (isAbsoluteImageUrl(src)) {
      return src
    }
    if (width < 800) {
      width *= 2
    }
    return cloudflareLoader({ src: src, width, quality: quality ?? 80 })
  }
  return null
}

export const getUnSplashUrl = ({
  src,
  width,
  quality,
}: {
  src?: string | null
  width?: number
  quality?: number
}) => {
  if (src && src.length > 0) {
    const urlParams = new URLSearchParams(src)
    // delete pre existing params which are saved in db
    urlParams.delete('w')
    urlParams.delete('fm')
    urlParams.delete('fit')
    urlParams.delete('q')
    urlParams.delete('crop')
    const newUrl = decodeURIComponent(
      urlParams + `&w=${width ?? 1080}&q=${quality ?? 80}&auto=format&fit=max`
    )
    return newUrl
  }
  return null
}
