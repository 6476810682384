import {
  AllPublishedBoardsData,
  AllSavedByUserBoardsData,
  ExploreBoardsDataType,
} from '~/endpoints/model/board'
import { ProfileSummaryPage, ProfileSummarySlice } from '~/endpoints/model/summaries'

export const placeholderGenericPage = {
  empty: true,
  numberOfElements: 0,
  offset: 0,
  pageNumber: 0,
  pageable: {
    number: 0,
    offset: 0,
    size: 0,
    sort: {
      orderBy: [],
      sorted: false,
    },
    sorted: false,
    unpaged: false,
  },
  size: 0,
}

export const placeholderGenericSlice = {
  empty: true,
  numberOfElements: 0,
  offset: 0,
  pageNumber: 0,
  pageable: {
    number: 0,
    offset: 0,
    size: 0,
    sort: {
      orderBy: [],
      sorted: false,
    },
    sorted: false,
    unpaged: false,
  },
  size: 0,
}

export const emptyAllPublishedBoardByUser: AllPublishedBoardsData = {
  ...placeholderGenericPage,
  content: [],
}

export const emptyAllSavedBoardByUser: AllSavedByUserBoardsData = {
  ...placeholderGenericPage,
  content: [],
}

export const emptyExploreBoardData: ExploreBoardsDataType = {
  ...placeholderGenericSlice,
  content: [],
}

export const emptyPeopleSearchdData: ProfileSummaryPage = {
  ...placeholderGenericPage,
  content: [],
}

export const emptyAllProfilesSavedByUser: ProfileSummarySlice = {
  ...placeholderGenericSlice,
  content: [],
}
