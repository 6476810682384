import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateAllPlacesCache } from '~/components/places/utils'
import { PlaceDetailsType } from '~/endpoints/model/places'
import { addPlacePhoto, deletePlaceImage } from '~/endpoints/places'

export const useUpdatePlacePhoto = () => {
  const queryClient = useQueryClient()

  const { isPending: isDeletePhotoLoading, mutateAsync: mutateDeletePlaceImage } = useMutation({
    mutationFn: ({ currentPlaceId, imageId }: { currentPlaceId: number; imageId: number }) =>
      deletePlaceImage(currentPlaceId, imageId),
    onSuccess: (placeDetails: PlaceDetailsType) => updateAllPlacesCache(queryClient, placeDetails),
  })

  const { isPending: isAddPhotoLoading, mutateAsync: mutateAddPlacePhoto } = useMutation({
    mutationFn: ({ currentPlaceId, url }: { currentPlaceId: number; url: string }) =>
      addPlacePhoto(currentPlaceId, url),
    onSuccess: (placeDetails: PlaceDetailsType) => updateAllPlacesCache(queryClient, placeDetails),
  })

  return { isDeletePhotoLoading, mutateDeletePlaceImage, isAddPhotoLoading, mutateAddPlacePhoto }
}
