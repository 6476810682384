import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect, useState } from 'react'

import { googleAnalyticsPageview } from '~/analytics/thatchGoogleAnalytics'
import { FullPageLoader } from '~/components/shared/loading/ThatchLoader'
import { useAnalytics } from '~/context/SegmentProvider'
import { REDIRECT_SOURCE_PARAM } from '~/endpoints/user'
import { pageview } from '~/utils/tag-manager'

export default function ThatchPage({ children }: PropsWithChildren) {
  const router = useRouter()
  const thatchSegment = useAnalytics()

  const [showLoading, setShowLoading] = useState<boolean>(false)

  // special hook for initial page load, where router changes are not in effect.
  // ex: when user types-in url to a page directly
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (!searchParams.get(REDIRECT_SOURCE_PARAM)) {
      thatchSegment.pageTrack(router.asPath)
      googleAnalyticsPageview(router.asPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleStart = (url: string) => {
      if (url !== router.asPath) {
        setShowLoading(true)
        thatchSegment.pageTrack(url)
        googleAnalyticsPageview(url)
      }
    }

    const handleComplete = (url: string) => {
      setShowLoading(false)
      pageview(url)
    }

    const handleError = (err: { cancelled: boolean }, url: string) => {
      if (err.cancelled) {
        console.error(`Route to ${url} was cancelled!`)
      }
      handleComplete(url)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleError)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events])

  return (
    <>
      {showLoading && <FullPageLoader />}
      {children}
    </>
  )
}
