import { useEffect } from 'react'

export const useWindowPopState = (token: string, callback: () => void) => {
  useEffect(() => {
    window.addEventListener('popstate', callback)

    return () => window.removeEventListener('popstate', callback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])
}
