import { z } from 'zod'

import { PlaceSummaryType } from '~/endpoints/model/places'

const PlaceEvents = {
  BookingUrlClicked: 'booking_com_url_clicked',
  AddPlaceClicked: 'add_place_clicked',
  AddPlaceSaved: 'add_place_saved',
} as const

export const PlaceEventsEnum = z.nativeEnum(PlaceEvents)
export type PlaceEventsType = z.infer<typeof PlaceEventsEnum>

export const PlaceEventPayloadSchema = z.object({
  name: z.string(),
  type: z.string(),
  lat: z.number().nullish(),
  long: z.number().nullish(),
  bookingUrl: z.string().nullish(),
  bookingClickLocation: z.string(),
  authorName: z.string(),
  authorUid: z.string(),
  authorUserName: z.string(),
  boardToken: z.string().nullish(),
  boardPrice: z.number().nullish(),
  stayStartDate: z.string().nullish(),
  stayEndDate: z.string().nullish(),
})

export const AddAPlaceEventPayloadSchema = z.object({
  name: z.string(),
  id: z.number().nullish(),
  sourceBoardToken: z.string(),
  clickLocation: z.string(),
  destinationBoardToken: z.string().nullish(),
})

export type PlaceEventPayload = z.infer<typeof PlaceEventPayloadSchema>
export type AddAPlaceEventPayload = z.infer<typeof AddAPlaceEventPayloadSchema>

export const buildPlaceEvent = (
  place: PlaceSummaryType,
  bookingUrl: string | undefined | null,
  bookingClickLocation: string,
  boardToken?: string,
  boardPrice?: number | undefined | null
): PlaceEventPayload => {
  const {
    name,
    type,
    location: { lat, lng: long },
    author,
  } = place

  let stayStartDate
  let stayEndDate
  if (bookingUrl) {
    // parse stay start and end date from booking url
    const urlParams = new URLSearchParams(bookingUrl)
    stayStartDate = urlParams.get('checkin')
    stayEndDate = urlParams.get('checkout')
  }
  return {
    name,
    type: type ?? 'do',
    lat,
    long,
    bookingUrl,
    bookingClickLocation,
    authorName: author?.name ?? '',
    authorUid: author?.uid ?? '',
    authorUserName: author?.username ?? '',
    boardPrice: boardPrice,
    boardToken: boardToken,
    stayStartDate,
    stayEndDate,
  }
}
