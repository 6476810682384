export const AddPinIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="15.4444"
        fill="black"
        stroke="black"
        strokeWidth="1.11111"
      />
      <g clipPath="url(#clip0_370_24377)">
        <path
          d="M21 13.1804H22.66V10.6204H25.22V8.96039H22.66V6.40039H21V8.96039H18.44V10.6204H21V13.1804ZM16 15.9204C16.4133 15.9204 16.77 15.7704 17.07 15.4704C17.37 15.1704 17.52 14.8137 17.52 14.4004C17.52 13.9871 17.37 13.6304 17.07 13.3304C16.77 13.0304 16.4133 12.8804 16 12.8804C15.5867 12.8804 15.23 13.0304 14.93 13.3304C14.63 13.6304 14.48 13.9871 14.48 14.4004C14.48 14.8137 14.63 15.1704 14.93 15.4704C15.23 15.7704 15.5867 15.9204 16 15.9204ZM16 24.4404C13.7467 22.5204 12.06 20.7404 10.94 19.1004C9.82001 17.4604 9.26001 15.9471 9.26001 14.5604C9.26001 12.4537 9.93668 10.7771 11.29 9.53039C12.6433 8.28372 14.2133 7.66039 16 7.66039C16.2133 7.66039 16.42 7.67039 16.62 7.69039C16.82 7.71039 17.0267 7.74039 17.24 7.78039V9.70039C17.04 9.64706 16.8333 9.60706 16.62 9.58039C16.4067 9.55372 16.2 9.54039 16 9.54039C14.64 9.54039 13.49 10.0104 12.55 10.9504C11.61 11.8904 11.14 13.0937 11.14 14.5604C11.14 15.4937 11.5467 16.5771 12.36 17.8104C13.1733 19.0437 14.3867 20.4271 16 21.9604C17.5467 20.5337 18.7167 19.2337 19.51 18.0604C20.3033 16.8871 20.74 15.8471 20.82 14.9404C20.82 14.8471 20.8167 14.7537 20.81 14.6604C20.8033 14.5671 20.7933 14.4737 20.78 14.3804H22.7C22.7133 14.4737 22.72 14.5671 22.72 14.6604V14.9404C22.6267 16.2871 22.0233 17.7471 20.91 19.3204C19.7967 20.8937 18.16 22.6004 16 24.4404Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_370_24377">
          <rect
            width="19.2"
            height="19.2"
            fill="white"
            transform="translate(6.3999 6.40039)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
