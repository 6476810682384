import { z } from 'zod'

import { DateSchema, GenericPage, GenericSlice } from '~/endpoints/model/common'
import { DiscountSchema } from '~/endpoints/model/discounts'
import { parseFactory } from '~/endpoints/model/util'

export type LanguageLevel = 'Basic' | 'Conversational' | 'Fluent' | 'Native'

export const LanguageSchema = z.object({
  name: z.string(),
  level: z.string(),
})
export type Language = z.infer<typeof LanguageSchema>

export const ProfileBasicSummarySchema = z.object({
  uid: z.string(),
  username: z.string(),
  name: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  cover: z.string().nullable(),
  photo: z.string().nullable(),
  bio: z.string().nullable(),
  hireable: z.boolean().default(false),
  languages: z.array(LanguageSchema).default([]),
})

const PaymentSummarySchema = z.object({
  price: z.number().nullable(),
  paid: z.boolean().default(false),
  date: DateSchema.nullable(),
})

const UserStateSchema = z.object({
  saved: z.boolean(),
  mine: z.boolean(),
  pinned: z.boolean(),
  payment: PaymentSummarySchema.nullish(),
})

const LocationSchema = z
  .object({
    id: z.number(),
    name: z.string().nullish(),
    address: z.string(),
    place_id: z.string(),
    position: z.record(z.string(), z.any()),
    types: z.array(z.string()),
  })
  .nullish()
const DiscoverTypes = {
  DestinationGuide: 'destination-guide',
  Itinerary: 'itinerary',
  MapOrList: 'map-or-list',
  SelfGuidedTour: 'self-guided-tour',
  PlanningService: 'planning-service',
  ConsultationCall: 'consultation',
  RecommendationService: 'recs',
}

export const DiscoverTypesEnum = z.nativeEnum(DiscoverTypes)
export const BoardSummarySchema = z.object({
  token: z.string(),
  id: z.number(),
  description: z.string().default(''),
  name: z.string().default(''),
  author: ProfileBasicSummarySchema,
  saveCount: z.number(),
  price: z.number().nullish(),
  coverImage: z.string().nullish().default(''),
  places: z.number(),
  links: z.number(),
  photos: z.number(),
  boards: z.number(),
  videos: z.number(),
  coverImages: z.array(z.string().nullish()),
  acceptTips: z.boolean(),
  publishDate: DateSchema.nullish(),
  published: z.boolean(),
  deleted: z.boolean(),
  state: UserStateSchema,
  created: DateSchema,
  updated: DateSchema,
  hasPromo: z.boolean(),
  discount: DiscountSchema.nullish(),
  anyone: z.boolean().nullish(),
  anytime: z.boolean().nullish(),
  bestTime: z.array(z.string()).nullish(),
  guideType: DiscoverTypesEnum.nullish(),
  destinations: z.array(LocationSchema).nullish(),
  designedFor: z.array(z.string()).nullish(),
  themes: z.array(z.string()).nullish(),
  template: z.boolean().default(false),
  rating: z.number().nullish(),
  reviews: z.number().nullish(),
})

export type BoardSummaryType = z.infer<typeof BoardSummarySchema>

export const SourceBoardSchema = z.object({
  token: z.string(),
  id: z.number(),
  name: z.string().default(''),
  coverImage: z.string().nullish().default(''),
  author: ProfileBasicSummarySchema,
})
export type SourceBoardType = z.infer<typeof SourceBoardSchema>

const ProfileUserStateSchema = z.object({
  mine: z.boolean(),
  following: z.boolean(),
  onboarding: z.record(z.string(), z.boolean()).nullish(),
})

export const ServiceTierSchema = z.object({
  price: z.number().nullish(),
  duration: z.number().nullish(),
  delivery: z.number().nullish(),
  recs: z.number().nullish(),
  service: z.string().nullish(),
})

export type ServiceTier = z.infer<typeof ServiceTierSchema>

export const QuestionSchema = z.object({
  description: z.string(),
  required: z.boolean().default(false),
})
export type Question = z.infer<typeof QuestionSchema>

export enum ThatchProStatus {
  none = 'none',
  newTalent = 'new-talent',
  newPro = 'new-pro',
  risingPro = 'rising-pro',
  topPro = 'top-pro',
}

export enum ServiceType {
  recs = 'recs',
  consultation = 'consultation',
  itinerary = 'itinerary',
}

export const getServiceTitle = (serviceType: ServiceType) => {
  switch (serviceType) {
    case ServiceType.consultation:
      return 'Consultation Calls'
    case ServiceType.itinerary:
      return 'Custom Itineraries'
    default:
      return 'Curated Recommendations'
  }
}

export const SellerServiceSchema = z.object({
  id: z.number(),
  type: z.nativeEnum(ServiceType),
  description: z.string(),
  disabled: z.boolean().default(false),
  base: ServiceTierSchema.nullish(),
  standard: ServiceTierSchema.nullish(),
  premium: ServiceTierSchema.nullish(),
  questions: QuestionSchema.array().default([]),
})
export type SellerService = z.infer<typeof SellerServiceSchema>

export const ProfileSummarySchema = ProfileBasicSummarySchema.extend({
  email: z.string().nullish(),
  bio: z.string().nullable(),
  website: z.string().nullable(),
  instagram: z.string().nullable(),
  tiktok: z.string().nullable(),
  youtube: z.string().nullable(),
  shopEnabled: z.boolean(),
  destination: z.boolean(),
  followers: z.number(),
  following: z.number(),
  stripeSellerId: z.string().nullable(),
  state: ProfileUserStateSchema,
  style: z.record(z.string(), z.string()),
  created: DateSchema,
  updated: DateSchema.nullable(),
  travelTypes: z.array(z.string()).nullish(),
  home: LocationSchema,
  locations: z.array(LocationSchema).default([]),
  languages: z.array(LanguageSchema).default([]),
  hireable: z.boolean(),
  admin: z.boolean().default(false),
  proStatus: z.nativeEnum(ThatchProStatus).default(ThatchProStatus.none),
  recs: SellerServiceSchema.nullish(),
  consultation: SellerServiceSchema.nullish(),
  itinerary: SellerServiceSchema.nullish(),
  sellerPhoto: z.string().nullish(),
  sellerIntroVideo: z.string().nullish(),
  resourceId: z.string().nullish(),
  locationId: z.string().nullish(),
  rating: z.number().nullish(),
  reviews: z.number().nullish(),
})

export const BoardSummaryListSchema = z.array(BoardSummarySchema).default([])

export const BoardSummarySliceSchema = GenericSlice.extend({
  content: z.array(BoardSummarySchema).default([]),
})

export const BoardSummaryPageSchema = GenericPage.extend({
  content: z.array(BoardSummarySchema).default([]),
})

export const ProfileSummaryPageSchema = GenericPage.extend({
  content: z.array(ProfileSummarySchema).default([]),
})

export const ProfileSummarySliceSchema = GenericSlice.extend({
  content: z.array(ProfileSummarySchema).default([]),
})

export type ProfileSummarySlice = z.infer<typeof ProfileSummarySliceSchema>

export type ProfileSummaryPage = z.infer<typeof ProfileSummaryPageSchema>

export type BoardSummaryPage = z.infer<typeof BoardSummaryPageSchema>

export type ProfileSummaryType = z.infer<typeof ProfileSummarySchema>

export type ProfileBasicSummaryType = z.infer<typeof ProfileBasicSummarySchema>

export type LocationType = z.infer<typeof LocationSchema>

export const ProfileDetailSchema = ProfileSummarySchema.extend({
  publishedBoards: BoardSummaryPageSchema,
})

export const emptyBoardSummaryPage: z.infer<typeof BoardSummaryPageSchema> = {
  content: [] as BoardSummaryType[],
  empty: true,
  numberOfElements: 0,
  offset: 0,
  pageNumber: 0,
  pageable: {
    size: 0,
    number: 0,
    sort: { orderBy: [] },
  },
  size: 0,
  totalPages: 0,
  totalSize: 0,
}

export const profileSummaryParser = parseFactory(ProfileSummarySchema, 'ProfileSummary')
export const profileSummarySliceParser = parseFactory(
  ProfileSummarySliceSchema,
  'ProfileSummarySlice'
)
export const profileSummaryPageParser = parseFactory(ProfileSummaryPageSchema, 'ProfileSummaryPage')

export const emptyProfileSummaryPage: z.infer<typeof ProfileSummaryPageSchema> = {
  content: [] as ProfileSummaryType[],
  empty: true,
  numberOfElements: 0,
  offset: 0,
  pageNumber: 0,
  pageable: {
    size: 0,
    number: 0,
    sort: { orderBy: [] },
  },
  size: 0,
  totalPages: 0,
  totalSize: 0,
}

export const emptyProfileSummarySlice: ProfileSummarySlice = {
  content: [] as ProfileSummaryType[],
  empty: true,
  numberOfElements: 0,
  offset: 0,
  pageNumber: 0,
  pageable: {
    size: 0,
    number: 0,
    sort: { orderBy: [] },
  },
  size: 0,
}
