import { z } from 'zod'

export const ExpressAvailabilitySchema = z.object({
  'getHotelExpress.Availability': z.object({
    error: z
      .object({
        status: z.string(),
      })
      .nullish(),
    results: z
      .object({
        status: z.string(),
        hotel_data: z.array(
          z.object({
            id: z.string(),
            name: z.string(),
            address: z.object({
              city_name: z.string().nullable(),
              address_line_one: z.string().nullable(),
            }),
          })
        ),
      })
      .nullish(),
  }),
})

export const AutoSuggestResultsSchema = z.object({
  getHotelAutoSuggestV2: z.object({
    error: z
      .object({
        status: z.string(),
      })
      .nullish(),
    results: z
      .object({
        status: z.string(),
        result: z.object({
          hotels: z.array(
            z.object({
              hotelid_ppn: z.string(),
              hotel_name: z.string(),
              address: z.object({
                city_name: z.string().nullable(),
                address_line_one: z.string().nullable(),
              }),
            })
          ),
        }),
      })
      .nullish(),
  }),
})

export const HotelDetailsSchema = z.object({
  getHotelHotelDetails: z.object({
    error: z
      .object({
        status: z.string(),
      })
      .nullish(),
    results: z
      .object({
        status: z.string(),
        hotel_data: z.array(
          z.object({
            id: z.string(),
            name: z.string().nullable(),
            booking_url: z.string().nullable(),
          })
        ),
      })
      .nullish(),
  }),
})
