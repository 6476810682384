import {
  ActionIcon,
  Box,
  CSSObject,
  Divider,
  Group,
  Loader,
  Modal,
  Stack,
  TextInput,
  createStyles,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconChevronRight, IconCircleCheck } from '@tabler/icons'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useState } from 'react'
import isEmail from 'validator/lib/isEmail'

import { ThatchButton } from '~/components/shared/ThatchButton'
import { thatchDialogStyle } from '~/components/shared/dialog'
import { notify } from '~/components/shared/notify'
import { Typography } from '~/components/shared/text/Typography'
import { deleteAccount, sendPasswordResetEmail, updateEmail } from '~/endpoints/user'
import { useAuth } from '~/hooks/useAuth'
import { useScreenSize } from '~/hooks/useMobileScreen'
import { captureSentryException } from '~/utils/sentry'

export interface AccountSettingsProps {
  onClose: () => void
  isOpened: boolean
  style: CSSObject
  userEmail: string
}

const useStyle = createStyles(theme => ({
  divider: {
    marginTop: 40,
    marginBottom: 30,

    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  delete: {
    color: '#B30808',
  },
  deleteButton: {
    border: `2px solid #B30808`,
  },
  deleteConfirmBody: {
    padding: '40px 24px',
  },
  deleteModalRoot: {
    zIndex: 1006,
  },
  emailChangedSuccess: {
    color: theme.colors.appGreen,
  },
}))

interface UpdateEmailFormValues {
  email: string
}

interface DeleteConfirmationProps {
  confirmDeleteAccount: boolean
  setConfirmDeleteAccount: Dispatch<SetStateAction<boolean>>
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = props => {
  const { classes } = useStyle()
  const { signOut } = useAuth()
  const router = useRouter()

  const onDeleteAccount = async () => {
    try {
      await deleteAccount()
      await signOut(() => {
        router.replace('/')
      })
    } catch (e) {
      captureSentryException(e)
      notify(true, 'Error deleting account')
    }
  }
  return (
    <Modal
      opened={props.confirmDeleteAccount}
      onClose={() => props.setConfirmDeleteAccount(false)}
      centered
      withCloseButton={false}
      classNames={{
        body: classes.deleteConfirmBody,
        root: classes.deleteModalRoot,
      }}
    >
      <Stack
        align="center"
        spacing="md"
      >
        <Typography variant="titleSerif">Caution</Typography>
        <Typography variant="body2">
          This will delete your personal data and your account from Thatch forever. You are of
          course welcome to create a new account at any time.
        </Typography>
        <Group
          sx={{
            paddingTop: 8,
          }}
        >
          <ThatchButton
            px={20}
            size="medium"
            color="appWhite.0"
            onClick={onDeleteAccount}
            label="Delete Account"
            classNames={{
              label: classes.delete,
            }}
            className={classes.deleteButton}
          />
          <ThatchButton
            px={40}
            size="medium"
            onClick={() => props.setConfirmDeleteAccount(false)}
            label="Cancel"
          />
        </Group>
      </Stack>
    </Modal>
  )
}

export const AccountSettings: React.FC<AccountSettingsProps> = props => {
  const { isMobileScreen } = useScreenSize()
  const { classes: dialogClasses } = thatchDialogStyle({})
  const { classes } = useStyle()
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false)
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (email: string) => updateEmail(email),
  })
  const [successEmailChanged, setSuccessEmailChanged] = useState(false)

  const form = useForm<UpdateEmailFormValues>({
    initialValues: {
      email: props.userEmail,
    },
    validate: {
      email: value => (isEmail(value) ? null : 'Enter a valid email'),
    },
  })

  const renderRightSection = () => {
    if (isPending) {
      return <Loader size={18} />
    } else if (successEmailChanged) {
      return (
        <IconCircleCheck
          size={24}
          className={classes.emailChangedSuccess}
        />
      )
    } else {
      return (
        <ActionIcon
          type="submit"
          sx={{ borderRadius: 0 }}
        >
          <IconChevronRight
            size={24}
            color="black"
          />
        </ActionIcon>
      )
    }
  }

  const handleSubmit = async ({ email }: UpdateEmailFormValues) => {
    try {
      await mutateAsync(email)
      setSuccessEmailChanged(true)
    } catch (err) {
      captureSentryException(err)
      notify(true, err.message)
    }
  }

  return (
    <Modal
      fullScreen={isMobileScreen}
      opened={props.isOpened}
      onClose={props.onClose}
      centered
      size={918}
      zIndex={1003}
      scrollAreaComponent={Modal.NativeScrollArea}
      classNames={{
        header: dialogClasses.header,
        content: dialogClasses.content,
        root: dialogClasses.modalRoot,
        close: dialogClasses.modalCloseButton,
      }}
      title={
        <Typography variant="titleSerif">
          Account{' '}
          <Typography
            span
            variant="titleSerifItalic"
          >
            settings
          </Typography>
        </Typography>
      }
    >
      <Box>
        {confirmDeleteAccount && (
          <DeleteConfirmation
            confirmDeleteAccount={confirmDeleteAccount}
            setConfirmDeleteAccount={setConfirmDeleteAccount}
          ></DeleteConfirmation>
        )}
        <Stack>
          <Group position="apart">
            <Stack spacing="xs">
              <Typography variant="title">Update Email</Typography>
              <Typography variant="caption">Double check your new email before saving</Typography>
            </Stack>
            <form
              onSubmit={form.onSubmit(handleSubmit)}
              style={{ maxWidth: 400, width: 400 }}
            >
              <TextInput
                placeholder="Your email address"
                rightSection={renderRightSection()}
                {...form.getInputProps('email')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSuccessEmailChanged(false)
                  return form.setFieldValue('email', event.currentTarget.value)
                }}
                styles={_theme => ({
                  error: {
                    color: 'red',
                    fontSize: 14,
                  },
                  input: {
                    height: 48,
                  },
                })}
              />
            </form>
          </Group>
          <Divider className={classes.divider} />
          <Group position="apart">
            <Stack spacing="xs">
              <Typography variant="title">Reset password</Typography>
              <Typography variant="caption">
                Send a password reset link to the email above
              </Typography>
            </Stack>
            <ThatchButton
              onClick={async () => {
                try {
                  await sendPasswordResetEmail(props.userEmail)
                  notify(false, 'A password reset link has been sent to your email')
                } catch (err) {
                  captureSentryException(err)
                  notify(true, err.message)
                }
              }}
              size="medium"
              label="Email link"
            />
          </Group>
          <Divider className={classes.divider} />
          <Group position="apart">
            <Stack spacing="xs">
              <Typography variant="title">Delete Account</Typography>
              <Typography variant="caption">Delete you personal data and account.</Typography>
            </Stack>
            <ThatchButton
              size="medium"
              color="appWhite.0"
              onClick={() => setConfirmDeleteAccount(true)}
              label="Delete Account"
              classNames={{
                label: classes.delete,
              }}
              className={classes.deleteButton}
            />
          </Group>
        </Stack>
      </Box>
    </Modal>
  )
}
