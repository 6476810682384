import { z } from 'zod'

import { DateSchema } from '~/endpoints/model/common'

export const DiscountStatusSchema = z.enum(['ACTIVE', 'SCHEDULED', 'EXPIRED'])
export const DiscountTypeSchema = z.enum(['SALE', 'PROMO'])

export const DiscountSchema = z.object({
  id: z.number(),
  code: z.string(),
  amount: z.number(),
  type: DiscountTypeSchema,
  status: DiscountStatusSchema,
  startDate: DateSchema,
  endDate: DateSchema.nullish(),
  usageLimit: z.number().nullish(),
  usageCount: z.number().default(0),
  boards: z.array(z.string()).default([]).nullish(),
  created: DateSchema,
  updated: DateSchema,
})
export const DiscountListSchema = z.array(DiscountSchema).default([])

export type DiscountType = z.infer<typeof DiscountSchema>
export type DiscountEnumType = z.infer<typeof DiscountTypeSchema>
export type DiscountListType = z.infer<typeof DiscountListSchema>
export type DiscountStatusType = z.infer<typeof DiscountStatusSchema>

const DiscountCreatePayloadSchema = z.object({
  code: z.string(),
  amount: z.number(),
  type: DiscountTypeSchema,
  startDate: z.string(),
  endDate: z.string().nullish(),
  usageLimit: z.number().nullish(),
  boards: z.array(z.string()), // array of guide tokens
})

export type DiscountCreatePayloadType = z.infer<typeof DiscountCreatePayloadSchema>
