import { uuid4 } from '@sentry/utils'
import {
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  getAdditionalUserInfo,
  getAuth,
  signInWithRedirect,
} from 'firebase/auth'

import { AuthViewEventPayload } from '~/analytics/AuthEvent'
import {
  CreateUser,
  CreateUserPayload,
  ProfileSummaryPage,
  ProfileSummarySlice,
  ProfileSummaryType,
  ThatchProStatus,
  emptyProfileSummarySlice,
  profileSummaryPageParser,
  profileSummaryParser,
  profileSummarySliceParser,
} from '~/endpoints/model'
import { getAuthClient as apiClient } from '~/utils/apiClient'
import { unknownAvatar } from '~/utils/helper'
import { SSRAuthToken } from '~/utils/ssr'
import { LocationPayloadType } from '~/utils/types'

export async function fetchSellersPage(
  page?: number,
  size?: number,
  auth?: SSRAuthToken
): Promise<ProfileSummaryPage> {
  const httpClient = apiClient(auth)
  const res = await httpClient.get(`/profile/v2/sellers/for-index`, {
    params: { page: page ?? 0, size: size ?? 100 },
  })
  return profileSummaryPageParser(res.data)
}

export const fetchMyProfile = async (auth?: SSRAuthToken): Promise<ProfileSummaryType> => {
  const httpClient = apiClient(auth)
  const res = await httpClient.get('/profile/v2/me')
  return profileSummaryParser(res.data)
}

export const fetchProfileSummary = async (userName: string, auth?: SSRAuthToken) => {
  const httpClient = apiClient(auth)
  const res = await httpClient.get(`/profile/v2/${userName}`)
  return profileSummaryParser(res.data)
}

export const followUnfollow = async (userName: string, isFollowing: boolean) => {
  const httpClient = apiClient()

  const endpoint = `/profile/v2/follow/${userName}`
  let res
  if (isFollowing) {
    res = await httpClient.delete(endpoint) //unfollow
  } else {
    res = await httpClient.post(endpoint) //follow
  }
  return profileSummaryParser(res.data)
}

export const fetchAllProfilesSavedByUser = async (userName: string, auth?: SSRAuthToken) => {
  const httpClient = apiClient(auth)
  const res = await httpClient.get(`/profile/v2/saved-by/${userName}`, {
    params: { size: 100 }, // no paginated results just yet
  })
  return profileSummarySliceParser(res.data)
}

export const fetchFeaturedSellers = async (auth?: SSRAuthToken): Promise<ProfileSummarySlice> => {
  const featuredContentUser = process.env.NEXT_PUBLIC_FEATURED_CONTENT_USERNAME
  // Prevent an invalid API call in environments that don't have a featured content user setup.
  if (!featuredContentUser) {
    return { ...emptyProfileSummarySlice }
  }
  return fetchAllProfilesSavedByUser(featuredContentUser, auth)
}

export const patchProfile = async (
  updates: Record<string, unknown>,
  auth?: SSRAuthToken
): Promise<ProfileSummaryType> => {
  const httpClient = apiClient(auth)
  const res = await httpClient.patch(`/profile/v2/me`, updates)
  return profileSummaryParser(res.data)
}

export const updateThatchProStatus = async (
  uid: string,
  status: ThatchProStatus,
  auth?: SSRAuthToken
): Promise<ProfileSummaryType> => {
  const httpClient = apiClient(auth)
  const res = await httpClient.post(`/profile/v2/seller/${uid}/pro-status`, status, {
    headers: { 'Content-Type': 'application/json' },
  })
  return profileSummaryParser(res.data)
}

export const checkUserName = async (userName: string) => {
  try {
    const httpClient = apiClient()
    await httpClient.get(`/profile/v2/username?check=${userName}`)
    return true
  } catch (error) {
    return false
  }
}

export const updateUserName = async (userName: string) => {
  const httpClient = apiClient()
  const res = await httpClient.put(`/profile/v2/username?to=${userName}`)
  return res.data
}

export const addUserLocation = async (location: LocationPayloadType) => {
  const httpClient = apiClient()
  const res = await httpClient.post(`/profile/v2/location`, location)
  return profileSummaryParser(res.data)
}

export const deleteUserLocation = async (id: number) => {
  const httpClient = apiClient()
  const res = await httpClient.delete(`/profile/v2/location/${id}`)
  return profileSummaryParser(res.data)
}

export const sendPasswordResetEmail = async (userEmail: string) => {
  await firebaseSendPasswordResetEmail(getAuth(), userEmail)
}

export const deleteAccount = async () => {
  const httpClient = apiClient()
  const res = await httpClient.delete(`/users`)
  return res.data
}

export const updateEmail = async (email: string) => {
  const httpClient = apiClient()
  const formData = new FormData()
  formData.append('email', email)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  const res = await httpClient.put(`/profile/v2/email`, formData, config)
  return profileSummaryParser(res.data)
}

const createUser = async (payload: CreateUserPayload) => {
  const httpClient = apiClient()
  return httpClient.post('/users', payload)
}

export const registerUser = async (input: CreateUser) => {
  const { email, password, ...rest } = input
  const newUser = await createUserWithEmailAndPassword(getAuth(), email, password)
  await createUser({ ...rest, email, uid: newUser.user?.uid })
  return newUser
}

export const REDIRECT_SOURCE_PARAM = 'redirect_source'

const buildRedirectSetup = (authEventPayload: AuthViewEventPayload, provider: string) => {
  // clean up any search pararms from the url
  const currentUrl = new URL(window.location.href)
  currentUrl.search = ''
  // build the context url which is used on ThatchPage.tsx after redirecting from auth screen
  const searchQuery = `${REDIRECT_SOURCE_PARAM}=${provider}&${Object.entries(authEventPayload)
    .map(entry => entry.join('='))
    .join('&')}`
  const redirectUrl = `${currentUrl}?${searchQuery}`
  // replace window state, so it can be read on ThatchPage.tsx after auth redirect
  window.history.replaceState({}, '', `${redirectUrl}`)
}

export const signInWithGoogle = async (authEventPayload: AuthViewEventPayload) => {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account',
  })
  buildRedirectSetup(authEventPayload, 'google')
  await signInWithRedirect(getAuth(), provider)
}

export const signInWithApple = async (authEventPayload: AuthViewEventPayload) => {
  const provider = new OAuthProvider('apple.com')
  provider.addScope('email')
  provider.addScope('name')
  buildRedirectSetup(authEventPayload, 'apple')
  await signInWithRedirect(getAuth(), provider)
}

export const googleSignInResultHandler = async (result: UserCredential) => {
  console.debug('Handling Google sign-in result', result.user.toJSON())
  const userInfo = getAdditionalUserInfo(result)
  if (userInfo?.isNewUser) {
    console.debug('Attempting to create new user in Thatch system')
    const { displayName, photoURL, uid, email } = result.user
    const nameParts = displayName?.split(' ')
    const CryptoJS = await import('crypto-js')
    const salt = uuid4()
    const secret = '3d494a84ea90210224d581d8f02bd2bc8270f3391afd50c7e1e66016a10e8622'
    const msg = `${salt}:${email}`
    const output = CryptoJS.enc.Hex.parse(secret)
    const hash = CryptoJS.HmacSHA256(msg, output).toString(CryptoJS.enc.Hex)
    if (email) {
      await createUser({
        token: hash,
        salt: salt,
        firstName: nameParts?.[0] ?? '',
        lastName: nameParts?.slice(1).join(' ') ?? '',
        uid,
        email: email,
        photo: photoURL ?? unknownAvatar(),
      })
      console.debug('New user created in Thatch system')
    } else {
      // this should not happen
      throw new Error('Email not present for user')
    }
  }
  return result.user
}
