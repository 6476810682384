import { LogLevel, Client as NotionClient } from '@notionhq/client'
import axios, { AxiosInstance } from 'axios'
import { getAuth } from 'firebase/auth'
import { StatusCodes } from 'http-status-codes'

import config from '~/thatch-config'
import { captureSentryException } from '~/utils/sentry'
import { SSRAuthToken, isServerSide } from '~/utils/ssr'

let unAuthHttpClient: AxiosInstance
let weatherHttpClient: AxiosInstance
let notionClient: NotionClient

const defaultOptions = {
  baseURL: config.apiEndpoint,
}

/**
 * Common authClient getter for client and server side rendering
 * @param ssrAuthToken : Optional ssrAuthToken getter used when making calls on server
 * @returns auth axios client instance
 */
const getAuthClient = (ssrAuthToken?: SSRAuthToken, isNextjsApi?: boolean) => {
  const authHttpClient = axios.create(isNextjsApi ? {} : defaultOptions)

  authHttpClient.interceptors.request.use(
    async request => {
      let token
      let addAuthHeader = false
      if (isServerSide()) {
        if (ssrAuthToken) {
          token = await ssrAuthToken()
          addAuthHeader = !!token
        }
      } else {
        const { currentUser } = getAuth()
        if (currentUser) {
          token = await currentUser.getIdToken()
          addAuthHeader = !!token
        }
      }
      if (addAuthHeader && token) {
        request.headers.set('authorization', `Bearer ${token}`)
      }
      return request
    },
    error => {
      console.error(`Failed to send request [${error.message}]: ${error.config.url}`, error)
      return Promise.reject(error)
    }
  )

  authHttpClient.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const status = error.response?.status as number
      if (status !== StatusCodes.OK) {
        console.error(`Failed to receive response [${error.message}]: ${error.config.url}`, error)
      }
      captureSentryException(error)
      return Promise.reject(error)
    }
  )
  return authHttpClient
}

const getUnAuthClient = () => {
  if (unAuthHttpClient == null) {
    unAuthHttpClient = axios.create(defaultOptions)

    unAuthHttpClient.interceptors.response.use(
      response => {
        return response
      },
      error => {
        const status = error.response?.status as number
        if (status !== StatusCodes.OK) {
          console.error(`Failed to receive response [${error.message}]: ${error.config.url}`, error)
        }
        // captureSentryException(error)
        return Promise.reject(error)
      }
    )
  }
  return unAuthHttpClient
}

const getWeatherClient = () => {
  if (weatherHttpClient == null) {
    weatherHttpClient = axios.create({ baseURL: 'https://api.openweathermap.org/data/2.5' })

    weatherHttpClient.interceptors.request.use(
      async request => {
        if (request.params === undefined) {
          request.params = {}
        }
        request.params.appid = `${config.openWeatherApiKey}`
        return request
      },
      error => {
        return Promise.reject(error)
      }
    )

    weatherHttpClient.interceptors.response.use(
      response => {
        return response
      },
      error => {
        const status = error.response?.status as number
        if (status !== StatusCodes.OK) {
          console.error(`Failed to receive response [${error.message}]: ${error.config.url}`, error)
        }
        // captureSentryException(error)
        return Promise.reject(error)
      }
    )
  }
  return weatherHttpClient
}

const getNotionClient = () => {
  if (notionClient == null) {
    notionClient = new NotionClient({
      auth: process.env.NOTION_API_KEY,
      logLevel: LogLevel.WARN,
    })
  }
  return notionClient
}

export { getAuthClient, getUnAuthClient, getWeatherClient, getNotionClient }
