import { z } from 'zod'

const ExploreEvents = {
  SearchViewed: 'explore_search_viewed',
  ResultClicked: 'explore_result_clicked',
  ShopClicked: 'explore_shop_clicked',
  ShopBoardClicked: 'explore_shop_board_clicked',
  SearchClicked: 'explore_search_clicked',
  SearchTypeFilterClicked: 'explore_search_type_filter_clicked',
  SearchPriceFilterClicked: 'explore_search_price_filter_clicked',
  SuggestedSearchBlockClicked: 'suggested_search_block_clicked',
  SuggestedSearchBlockViewed: 'suggested_search_block_viewed',
} as const

export const ExploreEventsEnum = z.nativeEnum(ExploreEvents)
export type ExploreEventsType = z.infer<typeof ExploreEventsEnum>

export const ExploreEventPayloadSchema = z.object({
  resultsCount: z.number().nullish(),
  searchQuery: z.string().nullish(),
  boardToken: z.string().nullish(),
  boardPrice: z.number().nullish(),
  shopDestination: z.string().nullish(),
  profileToken: z.string().nullish(),
  tabName: z.string().nullish(),
  pageNumber: z.number().nullish(),
  extra: z.record(z.string(), z.any()).nullish(),
})

export type ExploreEventPayload = z.infer<typeof ExploreEventPayloadSchema>
