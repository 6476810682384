import { useMutation } from '@tanstack/react-query'

import { getAuthClient as apiClient } from '~/utils/apiClient'

interface Params {
  name: string
  file: File | Blob
  uploadUrl: string
}

export const useFileUpload = () => {
  const httpClient = apiClient()
  const handleUpload = async (params: Params) => {
    const { name, file, uploadUrl } = params
    const formData = new FormData()
    formData.append(name, file)
    const { data }: { data: string } = await httpClient.post(uploadUrl, formData)
    return data
  }

  return useMutation({ mutationFn: (params: Params) => handleUpload(params) })
}
