import { createConsola } from 'consola/core'
import { getAuth, getRedirectResult } from 'firebase/auth'
import absoluteUrl from 'next-absolute-url'
import { init } from 'next-firebase-auth'

import { FINAL_DESTINATION_PARAM } from '~/analytics/AuthEvent'
import { googleSignInResultHandler } from '~/endpoints/user'
import config from '~/thatch-config'
import { isServerSide } from '~/utils/ssr'

const TWELVE_DAYS_IN_MS = 12 * 60 * 60 * 24 * 1000

const initAuth = () => {
  init({
    debug: config.firebaseAuthDebug,
    authPageURL: ({ ctx }) => {
      const origin = isServerSide() ? absoluteUrl(ctx?.req).origin : window.location.origin
      const destPath = typeof window === 'undefined' ? ctx?.resolvedUrl : window.location.href
      const destURL = new URL(destPath as string, origin)
      return `/login?destination=${encodeURIComponent(destURL.toString())}`
    },
    appPageURL: ({ ctx }) => {
      const isServer = isServerSide()
      const origin = isServer ? absoluteUrl(ctx?.req).origin : window.location.origin
      const params = isServer
        ? new URL(ctx?.req?.url ?? '/', origin).searchParams
        : new URLSearchParams(window.location.search)
      const destinationParamVal = params.get('destination')
        ? decodeURIComponent(params.get('destination') as string)
        : undefined
      let destURL = '/'
      if (destinationParamVal) {
        // Verify the redirect URL host is allowed for security reason.
        // Learn more: https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/11-Client_Side_Testing/04-Testing_for_Client_Side_URL_Redirect
        const allowed = config.authAllowedHosts.indexOf(new URL(destinationParamVal).host) > -1
        if (allowed) {
          destURL = destinationParamVal
        } else {
          console.info(
            `Redirect destination host must be one of ${config.authAllowedHosts.join(', ')}.`
          )
        }
      }
      return destURL
    },
    // Override the default implementation to allow us more time to hook into our systems before alerting the rest of
    // the app that we have a user authenticated.
    tokenChangedHandler: async function (authUser) {
      const log = createConsola({ level: 5 }).withTag('thatch-auth')
      /***
       * Handles any errors when performing the login request in a way that leaves the system in a consistent state.
       *
       * Cleanup performed:
       * - sign out
       * - clean up URL parameters that were added from OAuth2
       * - show an error notification to the user
       */
      async function onLoginRequestError(error: Error) {
        // console.error('Login request failed', error)
        // if (isClientSide()) {
        //   await signOut(getAuth())
        //   const currentUrl = new URL(window.location.href)
        //   currentUrl.search = ''
        //   window.history.replaceState({}, '', `${currentUrl}`)
        //   notify(true, 'Unable to verify user details', 'Login Failed')
        // }
        throw error
      }

      /*** This is the default logout request error handler. It is here to make overriding in the future easier. */
      async function onLogoutRequestError(error: Error) {
        throw error
      }

      async function onUserSignIn() {
        log.start('New user logged in: %s', authUser.id)

        const result = await getRedirectResult(getAuth())
        log.debug('Redirect Result', result)
        if (result) {
          const searchParams = new URLSearchParams(window.location.search)
          // const redirectSource = searchParams.get(REDIRECT_SOURCE_PARAM)
          const finalDestination = searchParams.get(FINAL_DESTINATION_PARAM)

          const user = await googleSignInResultHandler(result)
          log.trace('user', user.toJSON())
          const currentUrl = new URL(window.location.href)
          currentUrl.search = ''

          if (finalDestination && finalDestination != '/') {
            // similar logic present on register.tsx for email signup redirect
            log.debug('Handling redirect from register ex: /seller to /seller/setup')
            if (finalDestination == '/home') {
              location.assign('/')
            } else {
              location.assign(finalDestination)
            }
          } else {
            // clean up current page url after auth redirect
            window.history.replaceState({}, '', `${currentUrl}`)
          }
        }

        const userToken = await authUser.getIdToken()
        const response = await fetch('/api/login', {
          method: 'POST',
          headers: {
            Authorization: userToken ?? '',
          },
          credentials: 'include',
        })
        if (!response.ok) {
          const responseJSON = await response.json()

          // If the developer provided a handler for login errors,
          // call it and don't throw.
          // https://github.com/gladly-team/next-firebase-auth/issues/367
          const err = new Error(
            `Received ${response.status} response from login API endpoint: ${JSON.stringify(
              responseJSON
            )}`
          )
          await onLoginRequestError(err)
        }

        return response
      }

      async function onUserSignOut() {
        // If the user is not authed, call logout to unset the cookie.
        const response = await fetch('/api/logout', {
          method: 'POST',
          credentials: 'include',
        })
        if (!response.ok) {
          const responseJSON = await response.json()

          // If the developer provided a handler for logout errors,
          // call it and don't throw.
          // https://github.com/gladly-team/next-firebase-auth/issues/367
          const err = new Error(
            `Received ${response.status} response from logout API endpoint: ${JSON.stringify(
              responseJSON
            )}`
          )
          await onLogoutRequestError(err)
        }

        return response
      }

      // If the user is authed, call login to set a cookie.
      return authUser.id ? onUserSignIn() : onUserSignOut()
    },
    firebaseAdminInitConfig: {
      credential: {
        projectId: config.firebaseProjectId,
        clientEmail: config.firebaseClientEmail,
        privateKey: process.env.FIREBASE_PRIVATE_KEY
          ? JSON.parse(process.env.FIREBASE_PRIVATE_KEY)
          : undefined,
      },
      databaseURL: config.firebaseDatabaseUrl,
    },
    firebaseClientInitConfig: {
      apiKey: config.firebasePublicApiKey,
      /**
       * For google with redirect to work for mobile, need following steps.
       * 1. Set vercel url (sandbox.thatch.co, www.thatch.co) as auth domain here in this file
       * 2. Fix nextjs rewrite to be env based to the firebase app
       * 3. White list host and auth handler in Google cloud console under credentials.
       *  3a. See example here for redirector link:
       * https://console.cloud.google.com/apis/credentials/oauthclient/956496945061-fnolmcak2sfuaf1ubjkuln1rlq7g8nav.apps.googleusercontent.com?project=travel-hub-sandbox
       * 4. Check firebase console auth domains to have the domains (sandbox.thatch.co, thatch.co).
       * 5. For dev or localhost use the actual firebase auth domain, instead of (sandbox.thatch.co, www.thatch.co),
       * see thatchConfig
       */
      authDomain: config.firebaseAuthRedirectDomain,
      databaseURL: config.firebaseDatabaseUrl,
      projectId: config.firebaseProjectId,
    },
    cookies: {
      name: 'ThatchWeb',
      keys: [
        process.env.COOKIE_SECRET_CURRENT as string,
        process.env.COOKIE_SECRET_PREVIOUS as string,
      ],
      httpOnly: true,
      maxAge: TWELVE_DAYS_IN_MS,
      overwrite: true,
      path: '/',
      sameSite: 'lax',
      secure: config.cookieSecure,
      signed: true,
    },
  })
}

export default initAuth
