import { Flex, Group } from '@mantine/core'
import { useRouter } from 'next/router'

import { ThatchLink } from '~/components/shared/ThatchLink'
import { Typography } from '~/components/shared/text/Typography'
import { BANNER_HEIGHT } from '~/utils/constants'

export const ServicesRedirectHeader = () => {
  const router = useRouter()

  return (
    <Flex
      h={BANNER_HEIGHT}
      bg="appLavender.0"
      direction="column"
      align="center"
      justify="center"
      sx={{ borderBottom: '1px solid black', cursor: 'pointer' }}
      onClick={() => router.push('/services-manager')}
    >
      <Group
        spacing={4}
        align="center"
      >
        <Typography
          variant="caption"
          align="center"
          color="appBlack.0"
        >
          ✨ Start selling travel services!{' '}
          <ThatchLink
            to="/services-manager"
            sx={{ textDecoration: 'underline' }}
          >
            Learn more
          </ThatchLink>
        </Typography>
      </Group>
    </Flex>
  )
}
