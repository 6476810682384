import { MantineTheme } from '@mantine/core'

import { priceFormatter } from '~/utils/string'

export function random(start: number, stop: number) {
  return Math.floor(Math.random() * (stop - start + 1)) + start
}

/**
 * Use this utility method when using createStyles
 * @param theme
 * @returns Thatch app custom theme color
 */
export function thatchRandomColor(theme: MantineTheme) {
  const disabledColors = ['appBlack', 'appWhite', 'appPlaceholder', 'appLavender', 'appPlum']
  const thatchColors = Object.keys(theme.colors).filter(
    name => name.startsWith('app') && !disabledColors.includes(name)
  )

  const index = random(0, thatchColors.length - 1)

  return theme.colors[thatchColors[index]][0]
}

/**
 * Helper which converts theme color names to actual color value
 * @param theme
 * @returns Thatch app custom theme color raw value
 */
export function getRawColorValue(theme: MantineTheme, color: string) {
  const tokens = color.split('.')
  return theme.colors[tokens[0]][Number(tokens[1])]
}

/**
 * @param theme
 * @param color
 * @returns the actual theme color, when provided string name for it or a random color
 */
export function getThemeColorValue(theme: MantineTheme, color: string, defaultColor?: string) {
  const mantineColor = theme.colors[color] ?? theme.colors[defaultColor ?? '']
  return mantineColor ?? thatchRandomColor(theme)
}

/**
 * @param url
 * @returns True or false
 */
export function isYoutubeUrl(url: string) {
  const regExp = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
  return regExp.test(url)
}

/**
 * @param url
 * @returns True or false
 */
export function isTiktokUrl(url: string) {
  const regExp = /^(http(s)?:\/\/)?((w){3}.)?tiktok.com\/.+/gm
  return regExp.test(url)
}

/**
 * @param data
 * @returns boolean
 */
export function isEmptyObject(data: unknown) {
  return data && Object.keys(data).length === 0 && Object.getPrototypeOf(data) === Object.prototype
}

/**
 * @param digits
 * @returns random number
 */
export function generateRandomNumber(digits: number) {
  return Math.floor(Math.random() * 10 ** digits)
}

export const unknownAvatar = () => {
  const index = random(1, 5)
  return `${window.location.protocol}//${window.location.host}/images/user/unknown${index}.png`
}

export const getAspectRatio = (url: string) => {
  const image = new Image()
  image.src = url
  return image.height === 0 ? '3/2' : image.width / image.height
}

export function isNonNull<T>(value: T | null | undefined): value is T {
  if (value === null || value === undefined) {
    return false
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const unused: T = value
  return true
}

export const calculateDiscountedPrice = (price: number, discountAmount: number) => {
  const discountedPrice = price - (price * discountAmount) / 100
  return {
    raw: Number(discountedPrice.toFixed(2)),
    formatted: priceFormatter(discountedPrice),
  }
}

export function queryAsString(query: string | string[] | undefined): string | undefined {
  if (query === undefined) {
    return undefined
  }

  if (Array.isArray(query)) {
    return query.pop()
  }

  return query
}

export const formatPrice = (num: number) => {
  if (isNaN(Number(num))) {
    return '-'
  }
  if (num % 1 === 0) {
    return `$${num}`
  }
  return `$${num.toFixed(2)}`
}

export async function wait(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export const redirectToNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const getMobileOperatingSystem = () => {
  if (typeof navigator !== 'undefined') {
    const userAgent = navigator.userAgent

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
    }
    if (/android/i.test(userAgent)) {
      return 'Android'
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS'
    }
  }
  return 'unknown'
}
