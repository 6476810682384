import { Center, Group, SelectItemProps } from '@mantine/core'
import { forwardRef } from 'react'

import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'

interface ItemProps extends SelectItemProps {
  label: string
  value: string
}
export const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, value, ...others }: ItemProps, ref) => {
    return (
      <Group
        ref={ref}
        {...others}
        spacing={16}
        noWrap
      >
        <Center>
          <SvgIcon
            type={SvgIconType.SAVED_PLACES}
            width={24}
            height={24}
          />
        </Center>
        <Typography
          lineClamp={1}
          variant="body2"
        >
          {label || value}
        </Typography>
      </Group>
    )
  }
)

AutoCompleteItem.displayName = 'AutoCompleteItem'
