import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateAllPlacesCache } from '~/components/places/utils'
import { PlaceDetailsType, PlaceSummaryType } from '~/endpoints/model/places'
import { changePlaceType } from '~/endpoints/places'

export const useUpdatePlaceType = () => {
  const queryClient = useQueryClient()

  const { isPending: isUpdatePlaceTypeLoading, mutateAsync: mutateUpdatePlaceType } = useMutation({
    mutationFn: ({
      currentPlace,
      changedPlaceType,
    }: {
      currentPlace: PlaceSummaryType
      changedPlaceType: string
    }) => changePlaceType(currentPlace, changedPlaceType),
    onSuccess: (placeDetails: PlaceDetailsType) => updateAllPlacesCache(queryClient, placeDetails),
  })

  return { mutateUpdatePlaceType, isUpdatePlaceTypeLoading }
}
