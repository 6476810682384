import { AppShell } from '@mantine/core'
import React, { ReactElement, ReactNode, createContext, useState } from 'react'

import { Footer } from '~/components/layout/Footer'
import { TopNav } from '~/components/layout/TopNav'
import { useCurrentAuthState } from '~/context'
import { ProfileSummaryType } from '~/endpoints/model'

interface LayoutProps {
    myProfileData?: ProfileSummaryType | null
    children: ReactElement
    headerCustomLeftSection?: ReactNode
    customFooter?: ReactElement
    hideHeader?: boolean
    hideFooter?: boolean
}

export const NavBarOffsetContext = createContext<number>(0)


export const Layout: React.FC<LayoutProps> = props => {
    const { userProfile: userData, email } = useCurrentAuthState()
    const isAuthenticated = Boolean(email)
    const isCreator = Boolean(isAuthenticated && userData?.stripeSellerId)
    const { headerCustomLeftSection, customFooter, hideFooter, hideHeader } = props
    const [navBarOffset, setNavBarOffset] = useState(0)

    return (
        <NavBarOffsetContext.Provider value={navBarOffset}>
            <AppShell
                padding={0}
                header={
                    hideHeader ? undefined : (
                        <TopNav
                            textColor="appBlack.0"
                            isAuthenticated={isAuthenticated}
                            isCreator={isCreator}
                            userEmail={email}
                            profile={userData ?? undefined}
                            headerCustomLeftSection={headerCustomLeftSection}
                            setNavBarOffset={setNavBarOffset}
                        />
                    )
                }
                footer={!hideFooter ? customFooter ? customFooter : <Footer isCreator={isCreator} /> : <></>}
            >
                {props.children}
            </AppShell>
        </NavBarOffsetContext.Provider>
    )
}
