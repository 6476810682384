/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react'

import { ExperiementVariants } from '~/experiments/fetchExperiments'
const ExperiementVariantsContext = React.createContext<ExperiementVariants>({})
import { useAnalytics } from '~/context/SegmentProvider'
type Props = {
    children: React.ReactNode
    variants: ExperiementVariants
}
import { fetchExperiments } from '~/experiments/fetchExperimentsClient'

export const ExperiementVariantsProvider = ({ children, variants }: Props) => {
    console.log("EXPERRIMENT VARIANTS", variants)
    const thatchSegment = useAnalytics()
    const [experiments, setExperiments] = React.useState<ExperiementVariants>(variants)
    useEffect(() => {
        async function refreshExperiments() {
            console.log("REFRESHING EXPERIMENTS")
            const currentUser = await thatchSegment.getCurrentUser()
            const currentUserId =  currentUser.anonymousId()
            const experiments = await fetchExperiments(currentUserId ?? "")
            setExperiments(experiments)
            console.log("Got experiments", experiments)
        }
        refreshExperiments()
    }, [thatchSegment])
    return (
        <ExperiementVariantsContext.Provider value={experiments}>{children}</ExperiementVariantsContext.Provider>
    )
}

export const useVariant = (key: string) => {
    const result = React.useContext(ExperiementVariantsContext)
    if (!result) {
        throw new Error('Context used outside of its Provider!')
    }
    return result[key]
}
