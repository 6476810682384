import { Experiment, Variants } from '@amplitude/experiment-node-server';

const apiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
const experiment = Experiment.initialize(apiKey??"");

export type ExperiementVariants = Variants

export async function fetchExperiments(userId: string) {
    if (!apiKey) return {};
    const user = {
        user_id:userId,
    };
    
    const experiments = await experiment.fetchV2(user);
    
    return experiments;
}