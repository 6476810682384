import { z } from 'zod'

const AuthEvents = {
  SignUpStarted: 'signup_started',
  SignUpCompleted: 'signup_completed',
} as const

export const AuthEventsEnum = z.nativeEnum(AuthEvents)
export type AuthEventsType = z.infer<typeof AuthEventsEnum>

export type LaunchSource =
  | 'save_guide'
  | 'add_place'
  | 'unlock_guide'
  | 'tip'
  | 'subscribe'
  | 'register_seller'
  | 'menu_log_in'
  | 'menu_sign_up'
  | 'personalize'
  | 'buy_service'
  | 'create_guide'

export interface AuthViewEventPayload {
  button_clicked: LaunchSource
  source_url: string
  board_token?: string
  user_uid?: string
}

export const FINAL_DESTINATION_PARAM = 'final_destination'

export interface AuthRedirectEventPayload extends AuthViewEventPayload {
  [FINAL_DESTINATION_PARAM]?: string
}
