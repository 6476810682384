import type { FirebaseOptions } from 'firebase/app'

const common = {
  cdnValidExtensions: ['png', 'jpg', 'jpeg', 'webp'],
  enableGoogleSignIn: process.env.NEXT_PUBLIC_GOOGLE_SIGNIN_ENABLED === 'true' ?? false,
}

const development = {
  ...common,
  firebaseAuthDebug: process.env.NEXT_ENABLE_FIREBASE_LOG === 'true' ?? true,
  firebaseProjectId: 'travel-hub-sandbox',
  firebaseClientEmail: 'thatch-sandbox@travel-hub-sandbox.iam.gserviceaccount.com',
  firebaseDatabaseUrl: 'https://travel-hub-sandbox.firebaseio.com',
  firebasePublicApiKey: 'AIzaSyCO36qaunw-bdnWHKBt6qfzik1YnHquL2c',
  firebaseAuthDomain: 'travel-hub-sandbox.firebaseapp.com',
  firebaseAuthRedirectDomain: 'travel-hub-sandbox.firebaseapp.com',
  authAllowedHosts: ['localhost:3000'],
  cookieSecure: false,
  apiEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.sandbox.thatch.co',
  imageEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.sandbox.thatch.co',
  audioEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.sandbox.thatch.co',
  cdnEndpoint: process.env.NEXT_PUBLIC_THATCH_CDN_ENDPOINT ?? 'https://cdn.sandbox.thatch.in',
  cdnDisabled: process.env.NEXT_PUBLIC_THATCH_CDN_DISABLED === 'true',
  unsplashApiKey: 'yrCZsfoKaqIdz2QLc5hLrWyKoMrX4jAqRY9Q0dU0Qv0',
  unsplashApiUrl: 'https://api.unsplash.com/search/photos',
  mapApiKey: 'AIzaSyD94Dvp5JA_MxTspsB2IeIefwnLW-UBzig',
  reCaptchaKey: '6LftcK4ZAAAAAJaDGYkhdpgEtwkVVHyQ9Hjr6ENY',
  googleImageProxyUrl: 'https://app.sandbox.thatch.co/proxy/maps/place/photo',
  openWeatherApiKey: '9c3bd8a564114d614b89289e8aaadbc3',
  bookingComApiKey: 'a6edb2bdd4117be3e9abd6d60309adb9',
  bookingComRFID: '10145',
  bookingComApiEndpoint: 'https://api-sandbox.rezserver.com',
  embedFeatureFlag: process.env.NEXT_PUBLIC_THATCH_ENABLE_EMBED === 'true',
  webappHost: process.env.NEXT_PUBLIC_THATCH_HOST ?? 'http://localhost:3000',
}

const sandbox: typeof development = {
  ...common,
  firebaseAuthDebug: false,
  firebaseProjectId: 'travel-hub-sandbox',
  firebaseClientEmail: 'thatch-sandbox@travel-hub-sandbox.iam.gserviceaccount.com',
  firebaseDatabaseUrl: 'https://travel-hub-sandbox.firebaseio.com',
  firebasePublicApiKey: 'AIzaSyCO36qaunw-bdnWHKBt6qfzik1YnHquL2c',
  firebaseAuthDomain: 'travel-hub-sandbox.firebaseapp.com',
  firebaseAuthRedirectDomain: 'sandbox.thatch.co',
  authAllowedHosts: ['thatch-web.vercel.app', 'sandbox.thatch.co'],
  cookieSecure: true,
  apiEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.sandbox.thatch.co',
  imageEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.sandbox.thatch.co',
  audioEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.sandbox.thatch.co',
  cdnEndpoint: process.env.NEXT_PUBLIC_THATCH_CDN_ENDPOINT ?? 'https://cdn.sandbox.thatch.in',
  cdnDisabled: process.env.NEXT_PUBLIC_THATCH_CDN_DISABLED === 'true',
  unsplashApiKey: 'yrCZsfoKaqIdz2QLc5hLrWyKoMrX4jAqRY9Q0dU0Qv0',
  unsplashApiUrl: 'https://api.unsplash.com/search/photos',
  mapApiKey: 'AIzaSyD94Dvp5JA_MxTspsB2IeIefwnLW-UBzig',
  reCaptchaKey: '6LftcK4ZAAAAAJaDGYkhdpgEtwkVVHyQ9Hjr6ENY',
  googleImageProxyUrl: 'https://app.sandbox.thatch.co/proxy/maps/place/photo',
  openWeatherApiKey: '9c3bd8a564114d614b89289e8aaadbc3',
  bookingComApiKey: 'a6edb2bdd4117be3e9abd6d60309adb9',
  bookingComRFID: '10145',
  bookingComApiEndpoint: 'https://api-sandbox.rezserver.com',
  embedFeatureFlag: process.env.NEXT_PUBLIC_THATCH_ENABLE_EMBED === 'true',
  webappHost: process.env.NEXT_PUBLIC_THATCH_HOST ?? 'https://sandbox.thatch.co',
}

const production: typeof development = {
  ...common,
  firebaseAuthDebug: false,
  firebaseProjectId: 'travel-hub-production',
  firebaseClientEmail: 'thatch-production@travel-hub-production.iam.gserviceaccount.com',
  firebaseDatabaseUrl: 'https://travel-hub-production.firebaseio.com',
  firebasePublicApiKey: 'AIzaSyCIQWgzO52QMnZRoNp-D3Wfkglf8GjahTs',
  firebaseAuthDomain: 'travel-hub-production.firebaseapp.com',
  firebaseAuthRedirectDomain: 'www.thatch.co',
  authAllowedHosts: ['www.thatch.co', 'thatch.co', 'thatch.in'],
  cookieSecure: true,
  apiEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.thatch.co',
  imageEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.thatch.co',
  audioEndpoint: process.env.NEXT_PUBLIC_THATCH_API_ENDPOINT ?? 'https://hub.svc.thatch.co',
  cdnEndpoint: process.env.NEXT_PUBLIC_THATCH_CDN_ENDPOINT ?? 'https://cdn.thatch.in',
  cdnDisabled: process.env.NEXT_PUBLIC_THATCH_CDN_DISABLED === 'true',
  unsplashApiKey: 'yrCZsfoKaqIdz2QLc5hLrWyKoMrX4jAqRY9Q0dU0Qv0',
  unsplashApiUrl: 'https://api.unsplash.com/search/photos',
  mapApiKey: 'AIzaSyDGYeVGJz0bGPwqm50U_tJs2HxKfvWMQ2E',
  reCaptchaKey: '6LcVca4ZAAAAAHb8N2-N_AEWb3JMQRqAQbiz8k6T',
  googleImageProxyUrl: 'https://app.thatch.co/proxy/maps/place/photo',
  openWeatherApiKey: '795fb0203ae5c6a97b1d05cc03c73a24',
  bookingComApiKey: '9476ba5a3937605e93849eca1c09bb99',
  bookingComRFID: '10145',
  bookingComApiEndpoint: 'https://api.rezserver.com',
  embedFeatureFlag: process.env.NEXT_PUBLIC_THATCH_ENABLE_EMBED === 'true',
  webappHost: process.env.NEXT_PUBLIC_THATCH_HOST ?? 'https://www.thatch.co',
}

let config: typeof production
switch (process.env.NEXT_PUBLIC_THATCH_ENV) {
  case 'production':
    config = production
    break
  case 'sandbox':
    config = sandbox
    break
  case 'development':
  default:
    config = development
    break
}

export type ThatchConfig = typeof development & {
  getCdnUrl: (path: string) => string
  getFirebaseConfig: () => FirebaseOptions
}

const thatchConfig: ThatchConfig = {
  ...config,

  getCdnUrl(path: string): string {
    const cleanPath = path.startsWith('/') ? path.slice(1) : path
    return `${config.cdnDisabled ? config.imageEndpoint : config.cdnEndpoint}/${cleanPath}`
  },

  getFirebaseConfig() {
    return {
      apiKey: this.firebasePublicApiKey,
      authDomain: this.firebaseAuthDomain,
      databaseURL: this.firebaseDatabaseUrl,
      projectId: this.firebaseProjectId,
    }
  },
}

export default thatchConfig
