import { Box } from '@mantine/core'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { LocalImage } from '~/components/shared/image/LocalImage'
import { Typography } from '~/components/shared/text/Typography'
import { isDevMode } from '~/utils/constants'

import asset404 from 'public/images/404.png'

export const ThatchError: React.FC<{ message: string; isErrorBoundary: boolean }> = ({
  message,
  isErrorBoundary,
}) => {
  const router = useRouter()

  useEffect(() => {
    if (isErrorBoundary && !isDevMode) {
      // try a force reload if coming from error boundary
      // used for any user sign up delay issues
      setTimeout(() => {
        router.reload()
      }, 500)
    }
  }, [isErrorBoundary, router])

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.colors.appPaper,
      })}
    >
      <LocalImage
        src={asset404}
        height={320}
        width={320}
      />
      <Typography
        variant="title"
        align="center"
      >
        {message}
      </Typography>
    </Box>
  )
}
