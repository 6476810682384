import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { ITimezoneOption } from 'react-timezone-select'

import { isClientSide } from '~/utils/ssr'

TimeAgo.addLocale(en)

export const timeAgo = new TimeAgo('en-US')

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export function getLocalTimezone() {
  if (isClientSide()) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  return undefined
}

export const getTimezone = (timezoneValue: string, timezoneOpts: ITimezoneOption[]) => {
  return (timezoneOpts.find(opt => opt.value === timezoneValue) ??
    timezoneOpts.find(
      opt => opt.offset === -(new Date().getTimezoneOffset() / 60)
    )) as ITimezoneOption
}
