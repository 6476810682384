import { add, format, nextWednesday } from 'date-fns'

import {
  AutoSuggestResultsSchema,
  ExpressAvailabilitySchema,
  HotelDetailsSchema,
} from '~/endpoints/model/booking'
import { PlaceSummaryType } from '~/endpoints/model/places'
import { parseFactory } from '~/endpoints/model/util'
import { getAuthClient } from '~/utils/apiClient'
import { removeFromString } from '~/utils/string'

const autoSuggestResultsParser = parseFactory(AutoSuggestResultsSchema, 'AutoSuggestResultsParser')
const hotelDetailsResultsParser = parseFactory(HotelDetailsSchema, 'HotelDetailsResultParser')
const expressAvailabilityParser = parseFactory(
  ExpressAvailabilitySchema,
  'ExpressAvailablityParser'
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const doAuoSuggestHotelCall = async (hotelName: string) => {
  const httpClient = getAuthClient(undefined, true)

  const res = await httpClient.get('/api/booking/getAutoSuggestV2', {
    params: {
      string: hotelName,
      get_hotels: 'true',
      get_cities: 'false',
      max_results: 1,
      sort: 'rank',
    },
  })
  const allHotelResults = autoSuggestResultsParser(res.data)
  const { error, results } = allHotelResults.getHotelAutoSuggestV2
  if (error || !results) {
    throw new Error(error?.status ?? `No auto suggest results for hotel: ${hotelName}`)
  }
  return results.result.hotels
}

const matchHotelName = (hotelName: string, placeName: string) => {
  const removedWords = ['hotel', '-']
  const cleansedHotelName = removeFromString(removedWords, hotelName.toLowerCase())
  const cleansedPlacelName = removeFromString(removedWords, placeName.toLowerCase())
  if (cleansedHotelName == cleansedPlacelName) {
    console.debug('match found for -', 'hotel:', hotelName, '- place:', placeName)
    return true
  }
  return false
}

const fetchData = async (checkinDate: Date, checkoutDate: Date, place: PlaceSummaryType) => {
  const {
    google: { name },
    location: { lat = '', lng = '' },
  } = place
  const httpClient = getAuthClient(undefined, true)
  const res = await httpClient.get('/api/booking/getExpress.Availability', {
    params: {
      latitude: lat.toString(),
      longitude: lng.toString(),
      limit: 10,
      adults: 1,
      check_in: format(checkinDate, 'yyyy-MM-dd'),
      check_out: format(checkoutDate, 'yyyy-MM-dd'),
      sort_by: 'ds',
      radius: 5,
    },
  })
  const { error, results } = expressAvailabilityParser(res.data)['getHotelExpress.Availability']
  if (results) {
    const hotelData = results.hotel_data ?? []
    for (let h = 0; h < hotelData.length; h++) {
      const foundHotel = hotelData[h]
      // match on place name
      if (matchHotelName(foundHotel.name, name ?? '')) {
        return foundHotel.id
      }
    }
  }
  throw new Error(`No express availability results for hotel: ${error}`)
}

const doExpressAvailabilityCall = async (place: PlaceSummaryType) => {
  const currentDate = new Date()
  const taskArray = []

  for (let i = 0; i < 3; i++) {
    const nextDate = add(currentDate, { months: i + 1 })
    const checkinDate = nextWednesday(nextDate)
    const checkOutDate = add(checkinDate, { days: 1 })
    const task = fetchData(checkinDate, checkOutDate, place)
    taskArray.push(task)
  }
  try {
    return await Promise.any(taskArray)
  } catch (e) {
    throw new Error(`No booking.com results for: ${place.name}`)
  }
}

const fetchHotelDetails = async (hotelId: string) => {
  const httpClient = getAuthClient(undefined, true)
  const res = await httpClient.get('/api/booking/getHotelDetails', {
    params: {
      hotel_id: hotelId,
    },
  })
  const hotelDetails = hotelDetailsResultsParser(res.data)
  const { error, results } = hotelDetails.getHotelHotelDetails
  if (error || !results) {
    throw new Error(error?.status ?? `No hotel details found for ${hotelId}`)
  }
  return results.hotel_data
}

export const fetchBookingUrl = async (place: PlaceSummaryType) => {
  const hotelId = await doExpressAvailabilityCall(place)
  const hotelDetails = await fetchHotelDetails(hotelId)
  if (hotelDetails && hotelDetails.length > 0) {
    return hotelDetails[0].booking_url
  }
  // TODO(shane): remove this once we are happy to not capture it in Sentry.
  // captureSentryException(
  //   `Unable to fetch booking.com url for ${place.name}:[${place.location.lat}-${place.location.lng}]`
  // )
  throw new Error(`Unable to fetch booking.com url ${place.name}`)
}
