import { useState } from 'react'

import { PlaceEventsEnum } from '~/analytics/PlaceEvent'
import { UnderlineLink } from '~/components/shared/UnderlineLink'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { notify } from '~/components/shared/notify'
import { useAnalytics, useCurrentAuthState } from '~/context'
import { appendPlaces, saveBoardDetails } from '~/endpoints/board'
import { BoardDetails, BoardSummaryType } from '~/endpoints/model'
import { PlaceSummaryType } from '~/endpoints/model/places'
import { captureSentryException } from '~/utils/sentry'

export const useAddPlaceToGuide = () => {
  const thatchSegment = useAnalytics()

  const { email } = useCurrentAuthState()
  const [showInlineAuth, setShowInlineAuth] = useState<boolean>(false)

  const [isBoardSelectionDialogOpen, setIsBoardSelectionDialogOpen] = useState<boolean>(false)
  const [isCreateGuideDialogOpen, setIsCreateGuideDialogOpen] = useState<boolean>(false)

  const [selectedGuidePlace, setSelectedGuidePlace] = useState<PlaceSummaryType>()
  const [guideName, setGuideName] = useState<string>('')
  const [isAddPlaceToGuideLoading, setIsAddPlaceToGuideLoading] = useState<boolean>(false)

  const handleCloseBoardSelectionModal = () => {
    setIsBoardSelectionDialogOpen(false)
  }
  const handleClickAddToGuideButton = (
    place: PlaceSummaryType,
    clickLocation?: string,
    sourceBoardToken?: string
  ) => {
    if (clickLocation && sourceBoardToken) {
      thatchSegment.trackAddAPlaceEvent(PlaceEventsEnum.enum.AddPlaceClicked, {
        name: place.name,
        id: place.id,
        sourceBoardToken,
        clickLocation,
      })
    }
    setSelectedGuidePlace(place)
    if (!email) {
      setShowInlineAuth(true)
    } else {
      setIsBoardSelectionDialogOpen(true)
    }
  }
  const handleClickCreateGuideButton = () => {
    setIsBoardSelectionDialogOpen(false)
    setIsCreateGuideDialogOpen(true)
  }
  const handleCloseCreateGuideModal = () => {
    setIsCreateGuideDialogOpen(false)
    setIsBoardSelectionDialogOpen(true)
  }
  const handleSavePlaceToGuide = async (
    board: BoardSummaryType | BoardDetails,
    isNewBoard?: boolean,
    title?: string,
    desc?: string,
    clickLocation?: string,
    sourceBoardToken?: string
  ) => {
    if (selectedGuidePlace?.id) {
      try {
        setGuideName(title || board.name)
        setIsAddPlaceToGuideLoading(true)
        if (isNewBoard) {
          const newBoardDetails = board as BoardDetails
          await saveBoardDetails({
            ...newBoardDetails,
            name: title ?? '',
            description: desc ?? '',
          })
        }
        await appendPlaces(board.token, [selectedGuidePlace.id])

        if (clickLocation && sourceBoardToken) {
          thatchSegment.trackAddAPlaceEvent(PlaceEventsEnum.enum.AddPlaceSaved, {
            name: selectedGuidePlace.name,
            id: selectedGuidePlace.id,
            sourceBoardToken,
            clickLocation,
            destinationBoardToken: board.token,
          })
        }

        handleCloseBoardSelectionModal()
        notify(
          false,
          <>
            {selectedGuidePlace.name} added to{' '}
            <UnderlineLink
              href={`/guide/${board.token}/edit`}
              label={title || board.name}
            />
          </>,
          'Place added to your guide',
          <SvgIcon
            type={SvgIconType.SAVE_OUTLINE}
            width={24}
            height={24}
            fill="appWhite.0"
          />
        )
      } catch (error) {
        captureSentryException(
          `Error saving Place:${selectedGuidePlace.name} to Board:${board.name}, Error: ${error}`
        )
      }
      setIsAddPlaceToGuideLoading(false)
    }
  }
  const handleCreateGuide = (board: BoardDetails, title: string, desc: string) => {
    handleCloseCreateGuideModal()
    handleSavePlaceToGuide(board, true, title, desc)
  }

  const onUserSetupSuccess = async () => {
    setShowInlineAuth(false)
    setIsBoardSelectionDialogOpen(true)
  }

  return {
    isBoardSelectionDialogOpen,
    isCreateGuideDialogOpen,
    isAddPlaceToGuideLoading,
    handleClickAddToGuideButton,
    handleCloseBoardSelectionModal,
    handleSavePlaceToGuide,
    handleCreateGuide,
    handleClickCreateGuideButton,
    handleCloseCreateGuideModal,
    selectedGuidePlace,
    guideName,
    showInlineAuth,
    setShowInlineAuth,
    onUserSetupSuccess,
  }
}
