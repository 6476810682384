export const NorthEastIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="north_east_FILL0_wght400_GRAD0_opsz48 (1) 1">
        <path
          id="Vector"
          d="M3.36663 13L2.66663 12.3L10.9666 4H6.06663V3H12.6666V9.6H11.6666V4.7L3.36663 13Z"
          fill="#00A610"
        />
      </g>
    </svg>
  )
}
