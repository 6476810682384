import {
  Autocomplete,
  AutocompleteItem,
  Box,
  Center,
  MediaQuery,
  createStyles,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'

import { ExploreEventsEnum } from '~/analytics/ExploreEvent'
import { AutoCompleteItem } from '~/components/home/AutoCompleteItem'
import { MobileSearchModal } from '~/components/home/MobileSearchModal'
import { useGetPlacePredictions } from '~/components/places/hooks'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { useAnalytics } from '~/context/SegmentProvider'
import { useScreenSize } from '~/hooks'
import { typography } from '~/theme'
import { DESTINATIONS } from '~/utils/constants'

const useStyle = createStyles(
  (
    theme,
    {
      debouncedQuery,
      isShownOnTopNav,
      isShownOnMenu,
    }: { debouncedQuery: string; isShownOnTopNav?: boolean; isShownOnMenu?: boolean }
  ) => ({
    root: {
      width: isShownOnTopNav ? 357 : '100%',
      height: isShownOnTopNav ? 42 : isShownOnMenu ? 60 : 68,
      border: `1px solid ${theme.colors.appBlack}`,
      background: theme.colors.appWhite,
      fontSize: 16,

      [`@media (max-width: ${theme.breakpoints.md})`]: {
        width: isShownOnTopNav ? 230 : undefined,
      },
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        width: isShownOnMenu ? '100%' : '100%',
      },
      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        height: 60,
      },
    },
    input: {
      border: 'none',
      paddingLeft: isShownOnTopNav ? 14 : isShownOnMenu ? 16 : 18,
      fontSize: isShownOnTopNav ? 20 : isShownOnMenu ? 18 : 22,
      height: isShownOnTopNav ? 40 : isShownOnMenu ? 58 : 66,

      '::placeholder': {
        color: theme.colors.appPlaceholder,
      },

      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        paddingLeft: 16,
        height: 58,
      },
    },
    dropdown: {
      marginTop: -7,
      boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.08)',
      border: '1px solid rgba(0, 0, 0, 0.15)',
      borderRadius: 0,
      minHeight: debouncedQuery ? 300 : 380,
    },
    separator: {
      marginBottom: 8,
      padding: 0,
    },
    separatorLabel: {
      ...typography.tag,
      color: theme.colors.appBlack,
      textTransform: 'uppercase',
      fontSize: 13,
      margin: '0px !important',

      ':after': {
        display: 'none',
      },
    },
    itemsWrapper: {
      padding: isShownOnTopNav ? '32px 32px 52px 32px ' : '36px 34px 52px 34px',

      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    item: {
      borderRadius: 0,
      padding: '8px 0px',

      '[data-selected], &[data-hovered], :hover, :active, ::selection, :focus': {
        backgroundColor: theme.colors.appYellow,
      },
    },
    rightSection: {
      width: 'auto',
      marginRight: isShownOnTopNav ? 14 : 18,

      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        marginRight: 16,
      },
    },
    desktopSearchButton: {
      height: 40,
      backgroundColor: theme.colors.appBlack,
      ':hover': {
        backgroundColor: theme.colors.appBlack,
      },
    },
    desktopSearchIcon: {
      marginLeft: 10,
    },
    mobileSearchButton: {
      width: 30,
      height: 30,
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.appBlack,
    },
  })
)

interface SearchInputProps {
  customRef?: any
  isShownOnTopNav?: boolean
  isShownOnMenu?: boolean
  openSearch?: boolean
  setOpenSearch?: (value: boolean) => void
  handleCloseMenu?: () => void
}

export const SearchInput: React.FC<SearchInputProps> = ({
  isShownOnTopNav,
  isShownOnMenu,
  openSearch,
  customRef,
  setOpenSearch,
  handleCloseMenu,
}) => {
  const router = useRouter()
  const { name } = router.query
  const { isMobileScreen } = useScreenSize()
  const thatchSegment = useAnalytics()

  const ref = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string>('')
  const [debouncedQuery] = useDebouncedValue(value, 200)
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false)

  const { placePredictions } = useGetPlacePredictions(debouncedQuery, undefined, false, undefined)
  const autoCompleteData = useMemo(
    () =>
      placePredictions.map(item => ({
        ...item,
        value: item.description,
        place_id: item.place_id,
        group: 'Results',
      })),
    [placePredictions]
  )

  const { classes } = useStyle({ debouncedQuery, isShownOnTopNav, isShownOnMenu })
  const destinations = debouncedQuery ? autoCompleteData : DESTINATIONS

  useEffect(() => {
    if (name) {
      setValue((name as string) ?? '')
    }
  }, [name])

  useEffect(() => {
    if (!router.asPath.includes('explore') || isShownOnMenu) {
      // reset search input when not on explore page or mobile menu
      setValue('')
    }
  }, [isShownOnMenu, router])

  const handleNavigate = (route: string) => {
    router.push(route)
    if (isShownOnMenu && handleCloseMenu) {
      handleCloseMenu()
    }
  }
  const logAnalytics = (searchTerm: string) => {
    thatchSegment.trackExploreEvent(ExploreEventsEnum.enum.SearchClicked, {
      searchQuery: searchTerm,
      extra: {
        isOnMobileMenu: isShownOnMenu ?? false,
        isOnTopNav: isShownOnTopNav,
        isOnMainSearch: !isShownOnMenu && !isShownOnTopNav,
      },
    })
  }

  const handleItemSubmit = (item: AutocompleteItem) => {
    logAnalytics(item.value.toLowerCase())
    setOpenSearchModal(false)
    setOpenSearch?.(false)
    handleNavigate(`/explore/${item.place_id}?name=${item.value}`)
    setValue(item.value)

    if (customRef) {
      customRef.current?.blur()
    } else {
      ref.current?.blur()
    }
  }

  const handleSearchClick = () => {
    if (destinations[0]) {
      handleItemSubmit(destinations[0])
    }
  }

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false)
    if (setOpenSearch) {
      setOpenSearch(false)
    }
  }

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true)
    if (setOpenSearch) {
      setOpenSearch(true)
    }
  }

  return (
    <>
      <Autocomplete
        ref={customRef || ref}
        classNames={{
          root: classes.root,
          input: classes.input,
          dropdown: classes.dropdown,
          separator: classes.separator,
          separatorLabel: classes.separatorLabel,
          itemsWrapper: classes.itemsWrapper,
          item: classes.item,
          rightSection: classes.rightSection,
        }}
        placeholder={isShownOnTopNav || isShownOnMenu ? 'Explore destinations...' : 'Where to?'}
        maxDropdownHeight={500}
        limit={10}
        dropdownPosition="bottom"
        data={destinations}
        onItemSubmit={handleItemSubmit}
        itemComponent={AutoCompleteItem}
        value={value}
        onChange={setValue}
        hoverOnSearchChange
        filter={(_input, _prediction) => {
          // always show all predictions returned
          return true
        }}
        readOnly={isShownOnMenu || isMobileScreen}
        onClick={() => {
          if (isShownOnMenu || isMobileScreen) {
            handleOpenSearchModal()
          }
        }}
        rightSection={
          <Center onClick={handleSearchClick}>
            {isShownOnTopNav ? (
              <SvgIcon
                type={SvgIconType.SEARCH}
                width={20}
                height={20}
              />
            ) : isShownOnMenu ? (
              <Box className={classes.mobileSearchButton}>
                <SvgIcon
                  width={15}
                  height={15}
                  type={SvgIconType.SEARCH}
                  fill="appWhite.0"
                />
              </Box>
            ) : (
              <>
                <MediaQuery
                  largerThan="xs"
                  styles={{ display: 'none' }}
                >
                  <Box className={classes.mobileSearchButton}>
                    <SvgIcon
                      width={15}
                      height={15}
                      type={SvgIconType.SEARCH}
                      fill="appWhite.0"
                    />
                  </Box>
                </MediaQuery>
                <MediaQuery
                  smallerThan="xs"
                  styles={{ display: 'none' }}
                >
                  <ThatchButton
                    label="Search"
                    className={classes.desktopSearchButton}
                    classNames={{ rightIcon: classes.desktopSearchIcon }}
                    w={120}
                    size="small"
                    rightIcon={
                      <SvgIcon
                        width={15}
                        height={15}
                        type={SvgIconType.SEARCH}
                        fill="appWhite.0"
                      />
                    }
                  />
                </MediaQuery>
              </>
            )}
          </Center>
        }
      />
      {(openSearch || openSearchModal) && (
        <MobileSearchModal
          onClose={handleCloseSearchModal}
          value={value}
          setValue={setValue}
          data={destinations}
          onItemSubmit={handleItemSubmit}
          onSearchClick={handleSearchClick}
        />
      )}
    </>
  )
}
