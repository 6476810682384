import { Flex, Group } from '@mantine/core'

import { NorthEastIcon } from '~/components/shared/image/icons/NorthEastIcon'
import { Typography } from '~/components/shared/text/Typography'
import { useAnalytics } from '~/context/SegmentProvider'
import { useMobileOS } from '~/hooks/useMobileOS'
import { BANNER_HEIGHT } from '~/utils/constants'

export const MobileRedirectHeader = () => {
  const { storeLocation } = useMobileOS()
  const thatchSegment = useAnalytics()

  return (
    <Flex
      h={BANNER_HEIGHT}
      bg="#EEEEEC"
      direction="column"
      align="center"
      justify="center"
      sx={{ borderBottom: '1px solid black' }}
      onClick={() => {
        thatchSegment.trackMiscEvent('get_mobile_app', {
          location: storeLocation,
          currentUrl: window.location.href,
        })
        window.open(storeLocation, '_blank')
      }}
    >
      <Group
        spacing={4}
        align="center"
      >
        <Typography
          variant="caption"
          align="center"
          color="appGreen.1"
        >
          Get the Thatch app
        </Typography>
        <NorthEastIcon />
      </Group>
    </Flex>
  )
}
