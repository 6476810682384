import { useMutation, useQueryClient } from '@tanstack/react-query'

import { placeDetailsToSummaryParser } from '~/components/places/utils'
import { PlaceDetailsType, PlaceSummaryType } from '~/endpoints/model/places'
import { createPlace } from '~/endpoints/places'
import { ALL_PLACE_SUMMARY_QUERY_KEY } from '~/utils/constants'

export const useCreatePlace = () => {
  const queryClient = useQueryClient()

  const { isPending: isCreatePlaceLoading, mutateAsync: mutateCreatePlace } = useMutation({
    mutationFn: (placeDetails: PlaceSummaryType) => createPlace(placeDetails),
    onSuccess: (placeDetails: PlaceDetailsType) => {
      const placeSummary = placeDetailsToSummaryParser(placeDetails)
      queryClient.setQueryData(
        [ALL_PLACE_SUMMARY_QUERY_KEY],
        (oldData: PlaceSummaryType[] | undefined) => [placeSummary, ...(oldData ?? [])]
      )
    },
  })

  return { mutateCreatePlace, isCreatePlaceLoading }
}
