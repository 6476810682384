import { DefaultSeoProps } from 'next-seo'

import { HOST } from '~/seo/constants'

import img from 'public/images/og/home.jpg'

const config: DefaultSeoProps = {
  title: 'Buy, sell and discover unique travel',
  description: 'Browse unique travel guides and services curated by experts around the world.',
  openGraph: {
    title: 'Buy, sell and discover unique travel',
    description: 'Browse unique travel guides and services curated by experts around the world.',
    type: 'website',
    url: `${HOST}`,
    images: [
      {
        url: img.src,
        height: img.height,
        width: img.width,
      },
    ],
  },
  twitter: {
    cardType: 'summary_large_image',
  },
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'minimum-scale=1, initial-scale=1, width=device-width',
    },
  ],
  canonical: '',
  norobots: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
}

export default config
