import throttle from 'lodash.throttle'
import { useEffect, useState } from 'react'

// Reference: https://medium.com/mtholla/create-a-transitioning-header-using-react-hooks-like-mediums-c70ed211f7c9
export const useDocumentScrollThrottled = (
  callback: ({
    previousScrollTop,
    currentScrollTop,
  }: {
    previousScrollTop: number
    currentScrollTop: number
  }) => void
) => {
  const [, setScrollPosition] = useState(0)
  let previousScrollTop = 0

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } = document.documentElement || document.body

    setScrollPosition(previousPosition => {
      previousScrollTop = previousPosition
      return currentScrollTop
    })

    callback({ previousScrollTop, currentScrollTop })
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 10)

  useEffect(() => {
    window.addEventListener('scroll', handleDocumentScrollThrottled)

    return () => window.removeEventListener('scroll', handleDocumentScrollThrottled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
