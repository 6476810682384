export const DigitalMapIcon = ({ width, height }: { width?: number; height?: number }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3 21L8.725 18.675L3 20.95V5L8.725 3L15.3 5.3L21 3.025V18.975L15.3 21ZM14.45 19.125V6.5L9.55 4.85V17.475L14.45 19.125ZM15.95 19.125L19.5 17.95V5.15L15.95 6.5V19.125ZM4.5 18.825L8.05 17.475V4.85L4.5 6.025V18.825ZM15.95 6.5V19.125V6.5ZM8.05 4.85V17.475V4.85Z"
        fill="black"
      />
    </svg>
  )
}
