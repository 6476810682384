import { CSSObject, MantineTheme, Text, TextProps } from '@mantine/core'
import React from 'react'

import { MantineNextLink } from '~/components/shared/MantineNextLink'
import { mobileTypography, typography } from '~/theme/typography'

export type Variant = keyof typeof typography

interface TypographyProps extends Omit<TextProps, 'variant'> {
  variant?: Variant
  rawLink?: boolean
  nextLink?: boolean
  href?: string
  target?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  id?: string
  mobileVariant?: Variant
  mobileVaraintSource?: 'desktop' | 'mobile'
}

export const Typography: React.FC<TypographyProps> = ({
  variant = 'body1',
  children,
  sx,
  rawLink,
  href,
  target,
  nextLink,
  mobileVariant,
  mobileVaraintSource,
  ...rest
}) => {
  const desktopStyle: CSSObject = typography[variant]
  const smallerScreenStyle: CSSObject = mobileVariant
    ? mobileVaraintSource == 'desktop'
      ? typography[mobileVariant]
      : mobileTypography[mobileVariant]
    : mobileTypography[variant]
  const allStyles = (theme: MantineTheme) => {
    return {
      ...sx, // only works for standard sx object and not theme functions
      [theme.fn.largerThan('sm')]: { ...desktopStyle },
      [theme.fn.smallerThan('sm')]: { ...smallerScreenStyle },
    }
  }
  if (rawLink) {
    return (
      <Text
        span
        variant="link"
        component="a"
        href={href ?? '#'}
        target={target ?? undefined}
        sx={allStyles}
        {...rest}
      >
        {children}
      </Text>
    )
  } else if (nextLink) {
    return (
      <Text
        span
        component={MantineNextLink}
        href={href ?? '#'}
        sx={allStyles}
        {...rest}
      >
        {children}
      </Text>
    )
  }
  return (
    <Text
      sx={allStyles}
      {...rest}
    >
      {children}
    </Text>
  )
}
