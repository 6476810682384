import Link, { LinkProps } from 'next/link'
import React, { forwardRef } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface NextLinkProps
  extends Omit<LinkProps, 'onMouseEnter' | 'onClick'>,
    Omit<React.ComponentPropsWithoutRef<'a'>, 'href' | 'as'> {}

export const MantineNextLink = forwardRef<HTMLAnchorElement, NextLinkProps>((props, ref) => {
  const { href, as, replace, scroll, shallow, prefetch, locale, ...otherProps } = props

  return (
    <Link
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
      locale={locale}
      {...otherProps}
      ref={ref}
    >
      {props.children}
    </Link>
  )
})

MantineNextLink.displayName = 'NextLink'
