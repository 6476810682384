import { useInfiniteQuery } from '@tanstack/react-query'

import config from '~/thatch-config'
import { getUnAuthClient as apiClient } from '~/utils/apiClient'
import type { UnsplashPhotoResponse } from '~/utils/types'

const CACHE_TIMEOUT = 1000 * 60 * 30 // 30 minutes

export const useUnsplashPhotos = (query: string) => {
  const httpClient = apiClient()

  return useInfiniteQuery({
    queryKey: ['unsplashPhotos', query],
    queryFn: async ({ pageParam = 1, queryKey }): Promise<UnsplashPhotoResponse> => {
      const res = await httpClient.get(config.unsplashApiUrl, {
        params: {
          query: queryKey[1] || 'Travel',
          page: pageParam,
          per_page: 30,
          orientation: 'landscape',
        },
        headers: {
          'Accept-Version': `v1`,
          Authorization: `Client-ID ${config.unsplashApiKey}`,
        },
      })

      return res.data
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.total_pages > pages.length) {
        return pages.length + 1
      }
    },
    gcTime: CACHE_TIMEOUT,
  })
}
