import { useMemo, useState } from 'react'

import { PlaceDetailsType, PlaceSummaryType } from '~/endpoints/model/places'

export const usePlaceInformation = () => {
  const [selectedPlaceSummary, setSelectedPlaceSummary] = useState<PlaceSummaryType>()
  const [isShowInfoWindow, setIsShowInfoWindow] = useState<boolean>(false)
  const [isPlaceDetails, setIsPlaceDetails] = useState<boolean>(false)
  const [selectedPlaceDetails, setSelectedPlaceDetails] = useState<PlaceDetailsType>()

  const canSavePlace = useMemo(() => {
    return selectedPlaceSummary && !isShowInfoWindow
      ? !selectedPlaceSummary.id
        ? true
        : selectedPlaceSummary.state.mine
      : false
  }, [selectedPlaceSummary, isShowInfoWindow])
  const isSaved = useMemo(() => {
    return !!(selectedPlaceSummary && selectedPlaceSummary.id)
  }, [selectedPlaceSummary])

  return {
    selectedPlaceSummary,
    setSelectedPlaceSummary,
    selectedPlaceDetails,
    setSelectedPlaceDetails,
    isShowInfoWindow,
    setIsShowInfoWindow,
    isPlaceDetails,
    setIsPlaceDetails,
    canSavePlace,
    isSaved,
  }
}
