/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { QueryClient } from '@tanstack/react-query'

import { ExistPlace, PlaceResult } from '~/components/shared/googlemaps/types'
import {
  AllPlaceSummaryType,
  CreatePlaceRequestSchema,
  CreatePlaceRequestType,
  ImageSummaryType,
  PlaceActualType,
  PlaceDetailsSchema,
  PlaceDetailsType,
  PlaceSummarySchema,
  PlaceSummaryType,
  PlaceTypeValues,
  UpdatePlaceRequestSchema,
  UpdatePlaceRequestType,
} from '~/endpoints/model/places'
import thatchConfig from '~/thatch-config'
import { ALL_PLACE_SUMMARY_QUERY_KEY, MAX_SCREEN_WIDTH } from '~/utils/constants'

const FilteredPlaceTypes = ['establishment', 'point_of_interest']
const PlaceTypeMap: { [key: string]: string } = {
  accounting: PlaceTypeValues[1],
  airport: PlaceTypeValues[6],
  amusement_park: PlaceTypeValues[1],
  aquarium: PlaceTypeValues[1],
  art_gallery: PlaceTypeValues[1],
  atm: PlaceTypeValues[1],
  bakery: PlaceTypeValues[0],
  bank: PlaceTypeValues[1],
  bar: PlaceTypeValues[3],
  beauty_salon: PlaceTypeValues[1],
  bicycle_store: PlaceTypeValues[5],
  book_store: PlaceTypeValues[5],
  bowling_alley: PlaceTypeValues[1],
  bus_station: PlaceTypeValues[6],
  cafe: PlaceTypeValues[4],
  campground: PlaceTypeValues[2],
  camping: PlaceTypeValues[2],
  car_dealer: PlaceTypeValues[5],
  car_rental: PlaceTypeValues[6],
  car_repair: PlaceTypeValues[1],
  car_wash: PlaceTypeValues[1],
  casino: PlaceTypeValues[1],
  cemetery: PlaceTypeValues[1],
  church: PlaceTypeValues[1],
  city_hall: PlaceTypeValues[1],
  clothing_store: PlaceTypeValues[5],
  convenience_store: PlaceTypeValues[5],
  courthouse: PlaceTypeValues[1],
  dentist: PlaceTypeValues[1],
  department_store: PlaceTypeValues[5],
  doctor: PlaceTypeValues[1],
  drugstore: PlaceTypeValues[5],
  electrician: PlaceTypeValues[1],
  electronics_store: PlaceTypeValues[5],
  embassy: PlaceTypeValues[1],
  fire_station: PlaceTypeValues[1],
  florist: PlaceTypeValues[5],
  funeral_home: PlaceTypeValues[1],
  furniture_store: PlaceTypeValues[5],
  gas_station: PlaceTypeValues[6],
  gym: PlaceTypeValues[1],
  hair_care: PlaceTypeValues[1],
  hardware_store: PlaceTypeValues[5],
  hindu_temple: PlaceTypeValues[1],
  home_goods_store: PlaceTypeValues[5],
  hospital: PlaceTypeValues[1],
  hotel: PlaceTypeValues[2],
  insurance_agency: PlaceTypeValues[1],
  jewelry_store: PlaceTypeValues[5],
  laundry: PlaceTypeValues[1],
  lawyer: PlaceTypeValues[1],
  library: PlaceTypeValues[1],
  light_rail_station: PlaceTypeValues[6],
  liquor_store: PlaceTypeValues[3],
  local_government_office: PlaceTypeValues[1],
  locksmith: PlaceTypeValues[1],
  lodging: PlaceTypeValues[2],
  meal_delivery: PlaceTypeValues[0],
  meal_takeaway: PlaceTypeValues[0],
  mosque: PlaceTypeValues[1],
  movie_rental: PlaceTypeValues[1],
  movie_theater: PlaceTypeValues[1],
  moving_company: PlaceTypeValues[1],
  museum: PlaceTypeValues[1],
  night_club: PlaceTypeValues[3],
  painter: PlaceTypeValues[1],
  park: PlaceTypeValues[1],
  parking: PlaceTypeValues[6],
  pet_store: PlaceTypeValues[5],
  pharmacy: PlaceTypeValues[5],
  physiotherapist: PlaceTypeValues[1],
  plumber: PlaceTypeValues[1],
  police: PlaceTypeValues[1],
  post_office: PlaceTypeValues[1],
  primary_school: PlaceTypeValues[1],
  real_estate_agency: PlaceTypeValues[1],
  rental: PlaceTypeValues[2],
  restaurant: PlaceTypeValues[0],
  roofing_contractor: PlaceTypeValues[1],
  rv_park: PlaceTypeValues[2],
  school: PlaceTypeValues[1],
  secondary_school: PlaceTypeValues[1],
  shoe_store: PlaceTypeValues[5],
  shopping_mall: PlaceTypeValues[5],
  spa: PlaceTypeValues[1],
  stadium: PlaceTypeValues[1],
  storage: PlaceTypeValues[1],
  store: PlaceTypeValues[5],
  subway_station: PlaceTypeValues[6],
  supermarket: PlaceTypeValues[0],
  synagogue: PlaceTypeValues[1],
  taxi_stand: PlaceTypeValues[6],
  tourist_attraction: PlaceTypeValues[1],
  train_station: PlaceTypeValues[6],
  transit_station: PlaceTypeValues[6],
  travel_agency: PlaceTypeValues[1],
  university: PlaceTypeValues[1],
  veterinary_care: PlaceTypeValues[1],
  zoo: PlaceTypeValues[1],
}

const toImageUrl = (photoReference: string, maxWidth: number, maxHeight: number) => {
  return `/api/proxy/maps/place/photo?photoreference=${photoReference}&maxwidth=${maxWidth}&maxheight=${maxHeight}`
}

export const derivePlaceType = (
  placeType: PlaceActualType,
  googleTypes: string[] | undefined | null,
  _placeName?: string
) => {
  // lookup google types if actual place type is not present
  if (!placeType && googleTypes) {
    let type
    googleTypes.every(item => {
      if (PlaceTypeMap[item]) {
        type = PlaceTypeMap[item] as PlaceActualType
        return false
      }
    })
    return type
  }
  return placeType ?? 'do'
}

const getPlaceType = (response: PlaceResult) => {
  const googleTypes = response.types?.filter(item => !FilteredPlaceTypes.includes(item)) ?? []
  const type = derivePlaceType(undefined, googleTypes)
  return { googleTypes, type }
}

// Util to lookup places with null placeTypes, and use google types to default to correct type
export const getTypeForASavedPlace = (place: PlaceSummaryType) => {
  const googleTypes = place.google.types?.filter(item => !FilteredPlaceTypes.includes(item)) ?? []
  return derivePlaceType(place.type, googleTypes) ?? 'do'
}

const getImageList = (response: PlaceResult) => {
  let imageList: ImageSummaryType[] = []

  if (response.photos && response.photos?.length > 0) {
    imageList = response.photos.map(item => ({
      path: toImageUrl(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: photo_reference does not exist at PlacePhoto type
        item.photo_reference,
        Math.min(item.width, MAX_SCREEN_WIDTH),
        Math.min(item.height, 1024)
      ),
    }))
  }
  return imageList
}

export const googlePlaceSummaryParser = (response: PlaceResult) => {
  const { googleTypes, type } = getPlaceType(response)
  const imageList = getImageList(response)

  const formattedPlaceSummary: PlaceSummaryType = {
    name: response.name ?? '',
    notes: null,
    audio: null,
    google: {
      id: response.place_id!,
      types: googleTypes,
      name: response.name,
    },
    type: type ?? 'do',
    location: {
      // google place api type mismatch, so need to convert to number
      lat: response.geometry!.location!.lat as unknown as number,
      lng: response.geometry!.location!.lng as unknown as number,
      plusCode: response.plus_code?.global_code ?? null,
    },
    cover: imageList[0],
    images: imageList,
    state: {
      saved: false,
      mine: false,
      visited: false,
    },
    created: new Date().toString(),
    updated: new Date().toString(),
  }

  return PlaceSummarySchema.parse(formattedPlaceSummary)
}

export const googlePlaceDetailsParser = (response: PlaceResult) => {
  const placeSummary = googlePlaceSummaryParser(response)

  const formattedPlaceDetails: PlaceDetailsType = {
    ...placeSummary,
    author: null,
    google: {
      ...placeSummary.google,
      address: response.formatted_address ?? '',
      website: response.website ?? '',
      phone: response.international_phone_number ?? '',
    },
    notes: '',
    hours: response.opening_hours?.weekday_text ?? [],
  }

  return PlaceDetailsSchema.parse(formattedPlaceDetails)
}

export const createPlaceRequestParser = (googlePlaceDetails: PlaceSummaryType) => {
  const formattedCreatePlaceRequest: CreatePlaceRequestType = {
    address: googlePlaceDetails.google.address,
    coverImage: googlePlaceDetails.cover?.path
      ?.replace('/api/proxy/maps/place/photo', thatchConfig.googleImageProxyUrl)
      ?.concat(`&key=${thatchConfig.mapApiKey}`),
    googlePlaceId: googlePlaceDetails.google.id,
    googleTypes: googlePlaceDetails.google.types,
    lat: googlePlaceDetails.location.lat,
    lng: googlePlaceDetails.location.lng,
    name: googlePlaceDetails.name,
    phone: googlePlaceDetails.google.phone,
    type: googlePlaceDetails.type,
    website: googlePlaceDetails.google.website,
    photos:
      googlePlaceDetails.images.map(
        item =>
          `${item.path?.replace(
            '/api/proxy/maps/place/photo',
            thatchConfig.googleImageProxyUrl
          )}&key=${thatchConfig.mapApiKey}`
      ) ?? [],
    notes: googlePlaceDetails.notes,
    bookingUrl: googlePlaceDetails.bookingUrl,
  }

  return CreatePlaceRequestSchema.parse(formattedCreatePlaceRequest)
}

export const updatePlaceRequestParser = (googlePlaceDetails: PlaceSummaryType) => {
  const formatedUpdatePlaceRequest: UpdatePlaceRequestType = {
    id: googlePlaceDetails.id,
    name: googlePlaceDetails.name,
    notes: googlePlaceDetails.notes,
    audioNotes: googlePlaceDetails.audio,
    cover: googlePlaceDetails.cover,
    website: googlePlaceDetails.google.website,
    type: googlePlaceDetails.type,
    visited: googlePlaceDetails.state.visited,
  }

  return UpdatePlaceRequestSchema.parse(formatedUpdatePlaceRequest)
}

export const placeDetailsToSummaryParser = (placeDetails: PlaceDetailsType) => {
  return PlaceSummarySchema.parse(placeDetails)
}

export const checkPlaceIsSaved = (allPlaces: AllPlaceSummaryType, currentPlaceId: string) => {
  const index = allPlaces.findIndex(
    savedItem => savedItem.state.mine && savedItem.google.id === currentPlaceId
  )
  return { isSaved: index > -1, savedIndex: index }
}

export const getExistPlaces = (allPlaces: AllPlaceSummaryType, currentPlaceId: string) => {
  const places: ExistPlace[] = []
  allPlaces.forEach((item, index) => {
    if (item.google.id === currentPlaceId) {
      places.push({
        isExistingPlace: true,
        existIndex: index,
        authorName: item.author?.name ?? '',
        authorUid: item.author?.uid,
      })
    }
  })
  return places
}

export const updateAllPlacesCache = (queryClient: QueryClient, placeDetails: PlaceDetailsType) => {
  const placeSummary = placeDetailsToSummaryParser(placeDetails)
  queryClient.setQueryData(
    [ALL_PLACE_SUMMARY_QUERY_KEY],
    (oldData: PlaceSummaryType[] | undefined) => {
      if (oldData) {
        const updatedPlaceIndex = oldData.findIndex(item => item.id === placeSummary.id)
        if (updatedPlaceIndex > -1) {
          oldData.splice(updatedPlaceIndex, 1)
          return [placeSummary, ...oldData]
        }
        return [...oldData]
      }
    }
  )
}
