import { Avatar, Box, Center, Group, Indicator, Loader, Stack } from '@mantine/core'
import { IconChevronRight, IconPlus, IconSettings } from '@tabler/icons'

import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { getImageUrl } from '~/components/shared/image/helpers'
import { AddPinIcon } from '~/components/shared/image/icons/AddPinIcon'
import { DigitalMapIcon } from '~/components/shared/image/icons/DigitalMapIcon'
import { Typography } from '~/components/shared/text/Typography'
import { ProfileSummaryType } from '~/endpoints/model/summaries'
import { TT_COMMONS_MEDIUM } from '~/theme/typography'
import type { TopMenuItem } from '~/utils/types'

export const FOOTER_LINKS: {
  title: string
  links: {
    label: string
    to: string
    icon?: string
    openInNewWindow: boolean
    enablePrefetch: boolean
  }[]
}[] = [
  {
    title: 'Company',
    links: [
      {
        label: 'About',
        to: '/about',
        openInNewWindow: false,
        enablePrefetch: false,
      },
      {
        label: 'Careers',
        to: 'https://angel.co/company/thatch-travel/jobs',
        openInNewWindow: true,
        enablePrefetch: false,
      },
      {
        label: 'Start Selling',
        to: '/seller',
        openInNewWindow: false,
        enablePrefetch: false,
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        label: 'Help Center',
        to: 'https://thatch.notion.site',
        openInNewWindow: true,
        enablePrefetch: false,
      },
      {
        label: 'Seller Academy',
        to: 'https://thatch.notion.site/Seller-Academy-996bfd5e9d6443f2a58a955cd19aa7b4',
        openInNewWindow: true,
        enablePrefetch: false,
      },
      {
        label: 'Terms',
        to: 'https://thatch.notion.site/Terms-of-Service-3062a81446f64df0b4a3ddcd04531de6',
        openInNewWindow: true,
        enablePrefetch: false,
      },
      {
        label: 'Copyright',
        to: 'https://thatch.notion.site/Copyright-Policy-30cdb522002b477aa62e4369e768b971',
        openInNewWindow: true,
        enablePrefetch: false,
      },
      {
        label: 'Privacy',
        to: 'https://thatch.notion.site/Privacy-Policy-408c99bc42c14705a1c8158d74ec5bbe',
        openInNewWindow: true,
        enablePrefetch: false,
      },
    ],
  },
  {
    title: 'Connect',
    links: [
      {
        label: 'LinkedIn',
        to: 'https://www.linkedin.com/company/thatch-travel',
        icon: 'footer_linkedin',
        openInNewWindow: true,
        enablePrefetch: false,
      },
      {
        label: 'Instagram',
        to: 'https://www.instagram.com/thatch.travel',
        icon: 'instagram',
        openInNewWindow: true,
        enablePrefetch: false,
      },
      {
        label: 'Twitter',
        to: 'https://twitter.com/thatchtravel',
        icon: 'footer_twitter',
        openInNewWindow: true,
        enablePrefetch: false,
      },
      {
        label: 'TikTok',
        to: 'https://www.tiktok.com/@thatch.travel',
        icon: 'tiktok',
        openInNewWindow: true,
        enablePrefetch: false,
      },
    ],
  },
]

const getAvatarBlock = (profile?: ProfileSummaryType, isSmallScreen?: boolean) => {
  if (!profile) {
    return null
  }
  return (
    <Group
      spacing={12}
      noWrap
      position="right"
      my={8}
    >
      <Avatar
        size={56}
        src={getImageUrl({ src: profile.photo, width: 56 })}
        radius="xl"
      >
        <SvgIcon
          width={16}
          height={16}
          type={SvgIconType.NO_USER}
          fill="appWhite.0"
        />
      </Avatar>
      <Stack
        spacing={8}
        mr="auto"
      >
        <Typography
          variant={isSmallScreen ? 'title' : 'caption'}
          style={{ fontFamily: TT_COMMONS_MEDIUM }}
        >
          {profile.name}
        </Typography>
        <Typography
          variant={isSmallScreen ? 'title2' : 'caption'}
          color="appPlaceholder.0"
        >
          View your Page
        </Typography>
      </Stack>
      <IconChevronRight size={18} />
    </Group>
  )
}

const accountSettings = (
  <Group
    spacing={12}
    py={8}
    position="right"
  >
    <IconSettings size={22} />
    <Typography
      variant="caption"
      mr="auto"
    >
      Account Settings
    </Typography>
    <IconChevronRight size={18} />
  </Group>
)

const createGuideButton = (isLoading: boolean) => (
  <Center
    bg="appBlack.0"
    sx={{ height: 30, width: 30, borderRadius: 15 }}
  >
    {isLoading ? (
      <Loader
        color="white"
        size={14}
      />
    ) : (
      <IconPlus
        size={18}
        color="white"
      />
    )}
  </Center>
)

export const getLibraryMenuItems = (isLoading: boolean, isMobile?: boolean): TopMenuItem[] => {
  const items = [
    {
      label: 'Your Guides',
      href: '/guides',
      type: 'link',
      icon: (
        <DigitalMapIcon
          width={0}
          height={0}
        />
      ),
    },
    {
      label: 'Your Saved Places',
      href: '/places',
      type: 'link',
      icon: (
        <SvgIcon
          type={SvgIconType.SAVED_PLACES}
          width={24}
          height={24}
        />
      ),
    },
    {
      type: isMobile ? 'childrenDivider' : 'divider',
    },
    {
      label: 'Create a Guide',
      type: 'action',
      icon: createGuideButton(isLoading),
    },
  ]

  if (!isMobile) {
    items.push(
      {
        label: 'Save a Place',
        href: '/places',
        type: 'link',
        icon: <AddPinIcon />,
      },
      {
        label: 'Save/Buy Guides',
        href: '/',
        type: 'link',
        icon: (
          <Center
            bg="appBlack.0"
            sx={{ height: 30, width: 30, borderRadius: 15 }}
          >
            <SvgIcon
              width={16}
              height={16}
              type={SvgIconType.SEARCH}
              fill="appWhite.0"
            />
          </Center>
        ),
      }
    )
  }

  return items as TopMenuItem[]
}

export const AUTHENTICATED_MENU_ITEMS = (profile?: ProfileSummaryType): TopMenuItem[] => {
  const items = [
    {
      label: 'Profile',
      href: `/@${profile?.username ?? ''}`,
      type: 'link',
      customNode: getAvatarBlock(profile),
    },
    {
      type: 'divider',
    },
    {
      label: 'Seller Academy',
      href: 'https://thatch.notion.site/Seller-Academy-996bfd5e9d6443f2a58a955cd19aa7b4',
      type: 'link',
      linkTarget: '_blank',
      sellerOnly: true,
    },
    {
      label: 'Services Manager',
      href: '/services-manager',
      type: 'link',
      isNew: true,
      sellerOnly: true,
    },
    {
      label: 'Discounts Manager',
      href: '/discount-manager',
      type: 'link',
      sellerOnly: true,
    },
    {
      label: 'Shop Analytics',
      href: '/shop-analytics',
      type: 'link',
      sellerOnly: true,
    },
    {
      type: 'divider',
      sellerOnly: true,
    },
    {
      label: 'Help center',
      href: 'https://thatch.notion.site',
      type: 'link',
      linkTarget: '_blank',
    },
    {
      label: 'Share feedback',
      href: 'mailto:hello@thatch.co',
      type: 'link',
      linkTarget: '_blank',
    },
    {
      type: 'divider',
    },
    {
      label: 'About us',
      href: '/about',
      type: 'link',
    },
    {
      label: 'Careers',
      href: 'https://angel.co/company/thatch-travel/jobs',
      type: 'link',
      linkTarget: '_blank',
    },
    {
      type: 'divider',
    },
    {
      label: 'Account Settings',
      type: 'action',
      customNode: accountSettings,
    },
    {
      type: 'divider',
    },
    {
      label: 'Sign out',
      type: 'actionButton',
    },
  ]
  return items.filter(item => !item.sellerOnly || Boolean(profile?.stripeSellerId)) as TopMenuItem[]
}

export const UNAUTHENTICATED_MENU_ITEMS: TopMenuItem[] = [
  {
    label: 'Help center',
    href: 'https://thatch.notion.site',
    type: 'link',
    linkTarget: '_blank',
  },
  {
    type: 'divider',
  },
  {
    label: 'About us',
    href: '/about',
    type: 'link',
  },
  {
    label: 'Careers',
    href: 'https://angel.co/company/thatch-travel/jobs',
    type: 'link',
    linkTarget: '_blank',
  },
  {
    type: 'divider',
  },
  {
    label: 'Sign up',
    href: '/register',
    type: 'actionButton',
    isPrimary: true,
  },
  {
    label: 'Log in',
    href: '/login',
    type: 'actionButton',
    isPrimary: false,
  },
]

export const UNAUTHENTICATED_MOBILE_MENU_ITEMS: TopMenuItem[] = [
  { type: 'divider' },
  {
    label: 'Become a seller',
    href: '/seller',
    type: 'link',
    customNode: (
      <Group
        position="apart"
        px={16}
        py={31.5}
      >
        <Typography
          variant="eyebrow"
          transform="uppercase"
        >
          Become a seller
        </Typography>
        <IconChevronRight size={18} />
      </Group>
    ),
  },
  { type: 'divider' },
  {
    label: 'SUPPORT',
    type: 'accordion',
    accordionItems: [
      {
        label: 'Help Center',
        href: 'https://thatch.notion.site',
        type: 'link',
        linkTarget: '_blank',
      },
      {
        label: 'Share Feedback',
        href: 'mailto:hello@thatch.co',
        type: 'link',
        linkTarget: '_blank',
      },
    ],
  },
  { type: 'divider' },
  {
    label: 'COMPANY',
    type: 'accordion',
    accordionItems: [
      {
        label: 'About us',
        href: '/about',
        type: 'link',
      },
      {
        label: 'Careers',
        href: 'https://angel.co/company/thatch-travel/jobs',
        type: 'link',
        linkTarget: '_blank',
      },
    ],
  },
  { type: 'divider' },
  { type: 'space', spaceSize: 64 },
  {
    label: 'Sign up',
    href: '/register',
    type: 'actionButton',
    isPrimary: true,
  },
  { type: 'space', spaceSize: 24 },
  {
    label: 'Log in',
    href: '/login',
    type: 'actionButton',
    isPrimary: false,
  },
]

export const getAuthenticatedMobileMenuItems = (
  profile?: ProfileSummaryType,
  showDot?: boolean
): TopMenuItem[] => {
  const items = [
    {
      label: 'Profile',
      href: `/@${profile?.username ?? ''}`,
      type: 'link',
      customNode: (
        <Box
          px={16}
          pt={8}
        >
          {getAvatarBlock(profile, true)}
        </Box>
      ),
    },
    {
      type: 'space',
      spaceSize: 48,
    },
    {
      type: 'divider',
    },
    {
      label: 'Your library',
      type: 'accordion',
      accordionItems: [...getLibraryMenuItems(false, true)],
    },
    {
      type: 'divider',
    },
    profile?.stripeSellerId
      ? {
          label: 'SELLER HUB',
          type: 'accordion',
          accordionItems: [
            {
              label: 'Services Manager',
              href: '/services-manager',
              type: 'link',
              isNew: true,
            },
            {
              label: 'Discounts Manager',
              href: '/discount-manager',
              type: 'link',
            },
            {
              label: 'Shop Analytics',
              href: '/shop-analytics',
              type: 'link',
              sellerOnly: true,
            },
            {
              label: 'Seller Academy',
              href: 'https://thatch.notion.site/Seller-Academy-Home-Page-1e2b2b2b2b9a4b6e9b0b0b0b0b0b0b0b',
              type: 'link',
              linkTarget: '_blank',
            },
          ],
        }
      : {
          label: 'Become a seller',
          href: '/seller',
          type: 'link',
          customNode: (
            <Group
              position="apart"
              px={16}
              py={31.5}
            >
              <Typography
                variant="eyebrow"
                transform="uppercase"
              >
                Become a seller
              </Typography>
              <IconChevronRight size={18} />
            </Group>
          ),
        },
    {
      type: 'divider',
    },
    {
      label: 'Notifications',
      type: 'action',
      customNode: (
        <Group
          position="apart"
          px={16}
          py={31.5}
        >
          <Indicator
            disabled={!showDot}
            inline
            size={12}
            offset={-2}
            position="top-end"
            color="red"
            withBorder
          >
            <Typography
              variant="eyebrow"
              transform="uppercase"
            >
              Notifications
            </Typography>
          </Indicator>

          <IconChevronRight size={18} />
        </Group>
      ),
    },
    {
      type: 'divider',
    },
    {
      label: 'Account Settings',
      type: 'action',
      customNode: (
        <Group
          position="apart"
          px={16}
          py={31.5}
        >
          <Typography
            variant="eyebrow"
            transform="uppercase"
          >
            Account Settings
          </Typography>
          <IconChevronRight size={18} />
        </Group>
      ),
    },
    {
      type: 'divider',
    },
    {
      label: 'SUPPORT',
      type: 'accordion',
      accordionItems: [
        {
          label: 'Help Center',
          href: 'https://thatch.notion.site',
          type: 'link',
          linkTarget: '_blank',
        },
        {
          label: 'Share Feedback',
          href: 'mailto:hello@thatch.co',
          type: 'link',
          linkTarget: '_blank',
        },
      ],
    },
    { type: 'divider' },
    {
      label: 'COMPANY',
      type: 'accordion',
      accordionItems: [
        {
          label: 'About us',
          href: '/about',
          type: 'link',
        },
        {
          label: 'Careers',
          href: 'https://angel.co/company/thatch-travel/jobs',
          type: 'link',
          linkTarget: '_blank',
        },
      ],
    },
    { type: 'divider' },
    { type: 'space', spaceSize: 64 },
    {
      label: 'Sign out',
      type: 'actionButton',
    },
  ]

  return items as TopMenuItem[]
}
