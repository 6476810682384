import { Button, ButtonProps, Text, createPolymorphicComponent, createStyles } from '@mantine/core'
import { ReactNode, forwardRef } from 'react'

import { Typography, Variant } from '~/components/shared/text/Typography'
import { TT_COMMONS_MEDIUM } from '~/theme/typography'

const useStyle = createStyles(theme => ({
  xlarge: {
    height: 64,
  },
  large: {
    height: 60,
  },
  mobileLarge: {
    height: 48,
  },
  medium: {
    height: 48,
  },
  small: {
    height: 40,
    '@media (max-width: 768px)': {
        height: 48,
    }
  },
  extraSmall: {
    height: 32,
  },
  mobileXSmall: {
    height: 24,
  },
  button: {
    ':disabled': {
      backgroundColor: theme.colors.appPlaceholder,
      color: theme.colors.appWhite,
    },
  },
  leftIcon: {
    marginRight: 10,
  },
  outline: {
    ':disabled': {
      backgroundColor: 'transparent',
      color: theme.colors.appBlack,
      borderColor: theme.colors.appBlack,
      opacity: 0.5,
    },
  },
}))

export interface ThatchButtonProps extends Omit<ButtonProps, 'size'> {
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'mobileLarge' | 'xlarge' | 'mobileXSmall'
  label?: string | ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const ThatchButton = createPolymorphicComponent<'button', ThatchButtonProps>(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLButtonElement, ThatchButtonProps>(
    (
      {
        size = 'small',
        label,
        className,
        color = 'appBlack.0',
        children,
        onClick,
        variant,
        ...rest
      },
      ref
    ) => {
      const { classes, cx } = useStyle()
      let labelVariant: Variant = 'button_small'
      if (size == 'medium') {
        labelVariant = 'button_medium'
      } else if (size == 'extraSmall') {
        labelVariant = 'button_xsmall'
      } else if (size == 'large') {
        labelVariant = 'button_large'
      }

      const buttonRootClassName = cx(
        classes[size],
        classes.button,
        className,
        variant === 'outline' ? classes.outline : null
      )

      // special button to accomdate same font size needed on desktop and mobile
      // follow the stylesheet
      if (size == 'mobileLarge') {
        return (
          <Button
            ref={ref}
            className={buttonRootClassName}
            classNames={{ leftIcon: classes.leftIcon }}
            radius="xl"
            color={color}
            onClick={onClick}
            variant={variant}
            {...rest}
          >
            {label ? (
              <Text
                sx={_theme => ({
                  fontSize: '18px',
                  fontFamily: `${TT_COMMONS_MEDIUM}`,
                  fontWeight: 500,
                })}
              >
                {label}
              </Text>
            ) : (
              children
            )}
          </Button>
        )
      } else if (size == 'xlarge') {
        return (
          <Button
            ref={ref}
            className={buttonRootClassName}
            classNames={{ leftIcon: classes.leftIcon }}
            radius="xl"
            color={color}
            variant={variant}
            onClick={onClick}
            {...rest}
          >
            {label ? (
              <Text
                sx={_theme => ({
                  fontSize: '20px',
                  fontFamily: `${TT_COMMONS_MEDIUM}`,
                  fontWeight: 500,
                })}
              >
                {label}
              </Text>
            ) : (
              children
            )}
          </Button>
        )
      } else if (size == 'mobileXSmall') {
        return (
          <Button
            ref={ref}
            className={buttonRootClassName}
            classNames={{ leftIcon: classes.leftIcon }}
            radius="xl"
            color={color}
            variant={variant}
            onClick={onClick}
            {...rest}
          >
            {label ? (
              <Text
                sx={_theme => ({
                  fontSize: '14px',
                  fontFamily: `${TT_COMMONS_MEDIUM}`,
                  fontWeight: 500,
                })}
              >
                {label}
              </Text>
            ) : (
              children
            )}
          </Button>
        )
      }

      return (
        <Button
          ref={ref}
          className={buttonRootClassName}
          classNames={{ leftIcon: classes.leftIcon }}
          radius="xl"
          color={color}
          variant={variant}
          onClick={onClick}
          {...rest}
        >
          {label ? <Typography variant={labelVariant}>{label}</Typography> : children}
        </Button>
      )
    }
  )
)

ThatchButton.displayName = 'ThatchButton'
