import { Anchor, AnchorProps } from '@mantine/core'
import NextLink, { LinkProps } from 'next/link'
import React from 'react'
import type { UrlObject } from 'url'

const oneWindowOnly = process.env.NEXT_PUBLIC_ONE_WINDOW_ONLY

interface ThatchLinkProps extends AnchorProps, Omit<LinkProps, 'href'> {
  to: string | UrlObject
  isOpenInNewWindow?: boolean
  prefetch?: boolean
}

export const ThatchLink = ({
  to,
  children,
  color,
  isOpenInNewWindow,
  prefetch,
  ...rest
}: ThatchLinkProps) => {
  const disabled = typeof window !== 'undefined' && window.location.pathname === to

  return (
    <Anchor
      component={NextLink}
      href={to}
      prefetch={prefetch}
      weight="500"
      color={color || 'black'}
      target={isOpenInNewWindow && !oneWindowOnly && !disabled ? '_blank' : undefined}
      {...rest}
    >
      {children}
    </Anchor>
  )
}
