import { useOs } from '@mantine/hooks'
import { useEffect, useState } from 'react'

export const useMobileOS = () => {
  const os = useOs()
  const [isMobileOS, setIsMobileOS] = useState(false)
  const [storeLocation, setStoreLocation] = useState(
    'https://apps.apple.com/us/app/thatch-travel-plan-share/id1524536119'
  )

  useEffect(() => {
    if (os == 'android' || os == 'ios') {
      setIsMobileOS(true)
      if (os == 'android') {
        setStoreLocation('https://play.google.com/store/apps/details?id=co.thatch.apps.hub')
      }
    }
  }, [os])

  return { isMobileOS, storeLocation }
}
