export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ?? undefined

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const googleAnalyticsPageview = (url: string) => {
  if (GOOGLE_ANALYTICS_ID) {
    gtag('event', 'page_view', {
      page_path: url,
      send_to: `${GOOGLE_ANALYTICS_ID}`,
    })
  }
}
