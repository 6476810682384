import { LoadingOverlay, createStyles } from '@mantine/core'

import { LoadingSpinner } from '~/components/shared/loading/LoadingSpinnerDynamic'

interface ThatchLoaderProps {
  isVisible: boolean
  message?: string
  overlayBlur?: number
  isFullScreen?: boolean
  loaderStyle?: React.CSSProperties
}

const useStyle = createStyles((theme, { isFullScreen }: { isFullScreen: boolean }) => ({
  loadingOverlay: {
    position: isFullScreen ? 'fixed' : 'absolute',
  },
}))

export const ThatchLoader: React.FC<ThatchLoaderProps> = ({
  message,
  isVisible,
  overlayBlur,
  isFullScreen = true,
  loaderStyle,
}) => {
  const { classes } = useStyle({ isFullScreen })

  return (
    <LoadingOverlay
      visible={isVisible}
      overlayBlur={overlayBlur}
      overlayColor="#fff"
      zIndex={2001}
      overlayOpacity={isFullScreen ? 1 : undefined}
      className={classes.loadingOverlay}
      loader={
        <LoadingSpinner
          color="black"
          message={message}
          customStyle={loaderStyle}
        />
      }
    />
  )
}

// Only used for nextjs pages with authUser component for SSR
export const FullPageLoader = (props: Omit<ThatchLoaderProps, 'isVisible'>) => (
  <ThatchLoader
    isVisible
    {...props}
  />
)
