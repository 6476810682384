import { Global } from '@mantine/core'

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Portonovo',
            src: `url('/fonts/Portonovo_Family/PortonovoWeb-Regular.woff2') format("woff2")`,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontDisplay: 'optional',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Portonovo Italic',
            src: `url('/fonts/Portonovo_Family/PortonovoWeb-RegularItalic.woff2') format("woff2")`,
            fontWeight: 'normal',
            fontStyle: 'italic',
            fontDisplay: 'optional',
          },
        },
        {
          '@font-face': {
            fontFamily: 'TT Commons Regular',
            src: `url('/fonts/TT_Commons/TTCommonsClassic-Regular.woff2') format("woff2")`,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontDisplay: 'optional',
          },
        },
        {
          '@font-face': {
            fontFamily: 'TT Commons Medium',
            src: `url('/fonts/TT_Commons/TTCommonsClassic-Medium.woff2') format("woff2")`,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontDisplay: 'optional',
          },
        },
      ]}
    />
  )
}
