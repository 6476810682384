import { useState } from 'react'

import { useCreatePlace } from '~/components/places/hooks/useCreatePlace'
import { placeDetailsToSummaryParser } from '~/components/places/utils'
import { PlaceSummaryType } from '~/endpoints/model/places'
import { fetchGoogleMapPlaceDetails } from '~/endpoints/places'
import { nextSessionToken, sessionToken } from '~/utils/sessionToken'

export const useCreatePlaceOnInfoWindow = () => {
  const { mutateCreatePlace, isCreatePlaceLoading } = useCreatePlace()

  const [isGoogleMapPlaceDetailsLoading, setIsGoogleMapPlaceDetailsLoading] =
    useState<boolean>(false)

  const handleCreatePlaceOnInfoWindow = async (placeSummary: PlaceSummaryType) => {
    setIsGoogleMapPlaceDetailsLoading(true)
    nextSessionToken()
    const response = await fetchGoogleMapPlaceDetails(placeSummary.google.id, sessionToken)
    setIsGoogleMapPlaceDetailsLoading(false)
    if (response) {
      const createdPlaceDetails = await mutateCreatePlace(response)
      const createdPlaceSummary = placeDetailsToSummaryParser(createdPlaceDetails)
      return createdPlaceSummary
    }
  }

  const isCreateLoading = isGoogleMapPlaceDetailsLoading || isCreatePlaceLoading

  return { handleCreatePlaceOnInfoWindow, isCreateLoading }
}
