import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateAllPlacesCache } from '~/components/places/utils'
import { PlaceDetailsType, PlaceSummaryType } from '~/endpoints/model/places'
import { updateBookingUrl } from '~/endpoints/places'

export const useEditBookingUrl = () => {
  const queryClient = useQueryClient()

  const { isPending: isEditing, mutateAsync: mutateEditBookingUrl } = useMutation({
    mutationFn: ({
      currentPlace,
      bookingUrl,
    }: {
      currentPlace: PlaceSummaryType
      bookingUrl: string
    }) => updateBookingUrl(currentPlace, bookingUrl),
    onSuccess: (placeDetails: PlaceDetailsType) => updateAllPlacesCache(queryClient, placeDetails),
  })

  return { mutateEditBookingUrl, isEditing }
}
