import { useEffect, useRef } from 'react'

export function useTimeout(condition: boolean, callback: () => void, delay: number) {
  const callbackRef = useRef(callback)

  // Store a reference to the callback if it changes.
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (condition) {
      const handle = setTimeout(() => callbackRef.current(), delay)

      return () => {
        clearTimeout(handle)
      }
    }
  }, [condition, delay])
}
