import {
  AutocompleteItem,
  Box,
  Center,
  Group,
  Modal,
  Space,
  Stack,
  TextInput,
  createStyles,
} from '@mantine/core'

import { newThatchDialogStyle } from '~/components/shared/dialog'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'
import { mobileTypography } from '~/theme'

const useStyle = createStyles(theme => ({
  modalHeader: {
    padding: '32px 16px',
    marginBottom: 0,
  },
  modalBody: {
    padding: '0px 16px',
  },
  root: {
    border: `1px solid ${theme.colors.appBlack}`,
    background: theme.colors.appWhite,
    height: 60,
    width: '100%',
  },
  input: {
    ...mobileTypography.title2,
    border: 'none',
    height: 58,
    paddingLeft: 16,

    '::placeholder': {
      color: theme.colors.appPlaceholder,
    },
  },
  rightSection: {
    width: 'auto',
    marginRight: 16,
  },
  mobileSearchButton: {
    width: 30,
    height: 30,
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.appBlack,
  },
  item: {
    padding: '9px 0px',

    '[data-selected], &[data-hovered], :hover, :active, ::selection, :focus': {
      backgroundColor: theme.colors.appYellow,
      cursor: 'pointer',
    },
  },
}))

interface MobileSearchModalProps {
  onClose: () => void
  value: string
  setValue: (value: string) => void
  data: AutocompleteItem[]
  onItemSubmit: (item: AutocompleteItem) => void
  onSearchClick: () => void
}
export const MobileSearchModal: React.FC<MobileSearchModalProps> = ({
  onClose,
  value,
  setValue,
  data,
  onItemSubmit,
  onSearchClick,
}) => {
  const label = data && data.length > 0 ? data[0].group : ''
  const { classes: dialogClasses } = newThatchDialogStyle({})
  const { classes } = useStyle()

  return (
    <Modal
      opened
      onClose={onClose}
      fullScreen
      classNames={{
        root: dialogClasses.modalRoot,
        close: dialogClasses.modalCloseButton,
        header: classes.modalHeader,
        body: classes.modalBody,
      }}
      title={
        <Typography variant="h2Italic">
          Where{' '}
          <Typography
            span
            variant="h2"
          >
            to?
          </Typography>
        </Typography>
      }
    >
      <TextInput
        classNames={{
          root: classes.root,
          input: classes.input,
          rightSection: classes.rightSection,
        }}
        placeholder="Search destinations"
        value={value}
        onChange={e => setValue(e.target.value)}
        rightSection={
          <Center onClick={onSearchClick}>
            <Box className={classes.mobileSearchButton}>
              <SvgIcon
                width={15}
                height={15}
                type={SvgIconType.SEARCH}
                fill="appWhite.0"
              />
            </Box>
          </Center>
        }
      />
      <Space h={34} />
      <Stack spacing={24}>
        <Typography
          variant="caption"
          tt="uppercase"
        >
          {label}
        </Typography>

        <Stack spacing={0}>
          {data.map(item => (
            <Group
              key={item.value}
              spacing={16}
              noWrap
              onClick={() => onItemSubmit(item)}
              className={classes.item}
            >
              <Center>
                <SvgIcon
                  type={SvgIconType.SAVED_PLACES}
                  width={24}
                  height={24}
                />
              </Center>
              <Typography
                lineClamp={1}
                variant="body2"
              >
                {item.label || item.value}
              </Typography>
            </Group>
          ))}
        </Stack>
      </Stack>
    </Modal>
  )
}
