export const pluralize = (noun: string, count: number, showCount = true, suffix = 's') =>
  `${showCount ? count : ''} ${noun}${count !== 1 ? suffix : ''}`

export const priceFormatter = (price: number | null | undefined, prepend?: string) => {
  if (!price || price == 0) {
    return 'Free'
  }
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price)
  return prepend ? `${prepend} ${formattedPrice}` : formattedPrice
}

const maybeAddPrefix = (uri: string, prefix: string) => {
  if (uri == null || uri.startsWith('http') || uri.startsWith('www.')) {
    return uri
  }
  const sanitizedUri = String.raw`${uri}`.replace(new RegExp('^@?', 'g'), '')
  return `${prefix}${sanitizedUri}`
}

export const instagramUri = (uri: string) => {
  return maybeAddPrefix(uri, 'https://instagram.com/')
}
export const tiktokUri = (uri: string) => {
  return maybeAddPrefix(uri, 'https://www.tiktok.com/@')
}
export const youtubeUri = (uri: string) => {
  return maybeAddPrefix(uri, 'https://www.youtube.com/c/')
}

const THATCH_AID = '2163274'
export const buildBookingComUrl = (url: string, authorUid: string, sourceBoardToken?: string) => {
  let label = authorUid;
  if (sourceBoardToken) {
    label += `:${sourceBoardToken}`
  }
  return `${url}?aid=${THATCH_AID}&refclickid=${authorUid}&label=${label}` 
  // refclickid can be removed as we are migrating to the label field. I kept it here while we do the change in case of any issues, but it should be removed down the road 
}

// Used for checking accent markes in search query
// more here: https://www.davidbcalhoun.com/2019/matching-accented-strings-in-javascript/
const removeDiacritics = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const normalizedContains = (haystack: string, needle: string) => {
  const regExp = new RegExp(removeDiacritics(needle), 'gi')
  return regExp.test(removeDiacritics(haystack))
}

// see more: https://stackoverflow.com/questions/10436523/remove-a-long-dash-from-a-string-in-javascript
export const removeFromString = (words: string[], input: string) => {
  const unAccentedInput = removeDiacritics(input)
  return words.reduce((result, word) => {
    return result
      .replace(word, '') // replace removal word
      .replace(/\u2013|\u2014|\u2014|\u2015|\u200E/g, '') // replace javascript en-dash chars, they look like "-", also lrm characater from google place api response
      .replace(/\b\s{2,}/g, ' ') // replace 2 or more spaces with single space
      .trim()
  }, unAccentedInput)
}

export const slugify = (text: string, removeSpecialChars:boolean = true) => {
  let slug = text.toLocaleLowerCase().replace(/ /g, '-')
  if (removeSpecialChars) {
    slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^\w-]+/g, '')
  }
  return slug
}

export const deSlugify = (slug: string) => {
  return slug.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}