import { useCallback, useMemo, useState } from 'react'

import { LatLngBounds } from '~/components/shared/googlemaps/types'
import { AllPlaceSummaryType, PlaceSummaryType } from '~/endpoints/model/places'

export const useHandlePlaceFilterUpdated = (
  allPlaces: AllPlaceSummaryType,
  currentMapBounds?: LatLngBounds
) => {
  const [selectedPlaceTypes, setSelectedPlaceTypes] = useState<string[]>([])

  const handlePlaceFilterUpdated = (placeTypes: string[]) => {
    setSelectedPlaceTypes([...placeTypes])
  }

  // below uniquePlaces can replace filteredPlaces, filteredPlacesWithDups, and countMap
  // const uniquePlaces = useMemo(() => {
  //   return allPlaces.reduce((acc: Map<string, PlaceSummaryType[]>, place, idx) => {
  //     const googlePlaceId = place.google.id
  //     if (acc.get(googlePlaceId)) {
  //       acc.get(googlePlaceId)?.push(place)
  //     } else {
  //       acc.set(googlePlaceId, [place])
  //     }
  //     return acc
  //   }, new Map<string, PlaceSummaryType[]>())
  // }, [allPlaces])

  const filterPlaces = useCallback(
    (source: AllPlaceSummaryType) => {
      let newAllPlaces = [...source]
      let newSelectedPlaceTypes = [...selectedPlaceTypes]

      if (newSelectedPlaceTypes.length === 0) {
        return newAllPlaces
      }

      let placeOwner = 'all'
      const yoursIndex = newSelectedPlaceTypes.indexOf('yours')
      const othersIndex = newSelectedPlaceTypes.indexOf('others')

      if (yoursIndex > -1 && othersIndex > -1) {
        newSelectedPlaceTypes = newSelectedPlaceTypes.filter(
          item => item !== 'yours' && item !== 'others'
        )
      } else if (yoursIndex > -1) {
        placeOwner = 'yours'
        newSelectedPlaceTypes.splice(yoursIndex, 1)
      } else if (othersIndex > -1) {
        placeOwner = 'others'
        newSelectedPlaceTypes.splice(othersIndex, 1)
      }

      if (placeOwner === 'yours') {
        newAllPlaces = newAllPlaces.filter((item: PlaceSummaryType) => item.state.mine)
      } else if (placeOwner === 'others') {
        newAllPlaces = newAllPlaces.filter((item: PlaceSummaryType) => !item.state.mine)
      }

      if (newSelectedPlaceTypes.length === 0) {
        return newAllPlaces
      }

      return newAllPlaces.filter((item: PlaceSummaryType) =>
        newSelectedPlaceTypes.some(placeType => placeType === (item.type ?? 'do'))
      )
    },
    [selectedPlaceTypes]
  )

  // filtered places based on filters but with duplicates present
  const filteredPlacesWithDups = useMemo(() => {
    return filterPlaces(allPlaces)
  }, [allPlaces, filterPlaces])

  // filtered places based on filters but with NO duplicates present
  const filteredPlaces = useMemo(() => {
    const u = filteredPlacesWithDups.filter(
      (value, index, self) =>
        index === self.findIndex(t => t && value && t.google.id === value.google.id)
    )
    return u
  }, [filteredPlacesWithDups])

  // filtered places based on filters and bounds but with duplicates present
  const filteredPlacesWithDupsInBounds = useMemo(() => {
    return currentMapBounds
      ? filterPlaces(
          allPlaces.filter(item =>
            currentMapBounds.contains(
              new google.maps.LatLng(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                item.location.lat!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                item.location.lng!
              )
            )
          )
        )
      : []
  }, [filterPlaces, allPlaces, currentMapBounds])

  // filtered places based on filters and bounds but with NO duplicates present
  const filteredPlacesInBounds = useMemo(() => {
    const u = filteredPlacesWithDupsInBounds.filter(
      (value, index, self) =>
        index === self.findIndex(t => t && value && t.google.id === value.google.id)
    )
    return u
  }, [filteredPlacesWithDupsInBounds])

  // counter map for each places and how many times it appears in all places payload
  const countMap = useMemo(() => {
    return filteredPlacesWithDups.reduce((acc: Map<string, number[]>, place, idx) => {
      const googlePlaceId = place.google.id
      if (acc.get(googlePlaceId)) {
        acc.get(googlePlaceId)?.push(idx + 1)
      } else {
        acc.set(googlePlaceId, [idx + 1])
      }
      return acc
    }, new Map<string, number[]>())
  }, [filteredPlacesWithDups])

  return {
    selectedPlaceTypes,
    handlePlaceFilterUpdated,
    filteredPlacesWithDups,
    filteredPlaces,
    filteredPlacesWithDupsInBounds,
    filteredPlacesInBounds,
    countMap,
  }
}
