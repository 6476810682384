import Image, { ImageProps, StaticImageData } from 'next/image'

interface LocalImageProps extends Omit<ImageProps, 'alt'> {
  alt?: string
  src: StaticImageData
  noblur?: boolean
}

/**
 * Wrapper for NextJS Static Image component
 *
 * @param props
 * @returns
 */
export const LocalImage: React.FC<LocalImageProps> = props => {
  const { noblur, src, ...rest } = props
  return (
    <Image
      {...rest}
      src={src}
      placeholder={noblur ? 'empty' : 'blur'}
      alt={rest.alt ?? ''}
      quality={rest.quality ?? 100}
    />
  )
}
