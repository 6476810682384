import { MantineThemeOverride } from '@mantine/core'

import { TT_COMMONS_REGULAR } from '~/theme/typography'

export const themeOverride: MantineThemeOverride = {
  fontFamily: TT_COMMONS_REGULAR,
  colors: {
    appBlack: ['#000'],
    appWhite: ['#fff'],
    appYellow: ['#FBE69B'],
    appPink: ['#F7CFEE'],
    appPlaceholder: ['#595959'],
    appPaper: ['#F3F2EF'],
    appOrange: ['#D66F3D'],
    appPuddy: ['#EACEBF'],
    appFuscia: ['#D05BB9'],
    appMint: ['#D7E5CB'],
    appLavender: ['#CEA7F0'],
    appGreen: ['#7FBA64', '#00850D', '#00A610'],
    appPlum: ['#6A2E47'],
    appIce: ['#C8D8E9'],
    appPoppy: ['#EC5659'],
    appBlue: ['#95B6F9'],
    appSuccess: ['#00A610'],
    appRed: ['#B30808'],
    appDescription: ['#9C9C9C'],
  },
  // 1rem = 16px. i.e: 31.25rem = 500px, 50rem = 800px, etc
  breakpoints: {
    xs: '31.25rem',
    sm: '50rem',
    md: '62.5rem',
    lg: '75rem',
    xl: '87.5rem',
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
  },
  primaryColor: 'appBlack',
  components: {
    InputWrapper: {
      styles: _theme => ({
        error: {
          fontSize: 12,
        },
        label: {
          fontSize: 16,
        },
      }),
      defaultProps: {
        inputWrapperOrder: ['label', 'error', 'input', 'description'],
      },
    },
    Input: {
      styles: theme => ({
        input: {
          fontSize: 18,
          borderRadius: 0,
          height: 40,
          borderColor: theme.colors.appPaper,
          '&::placeholder': {
            color: theme.colors.appPlaceholder,
          },
          '&:focus': {
            borderColor: theme.colors.appBlack,
          },
          color: theme.colors.appBlack,
        },
        invalid: { borderColor: 'red' },
      }),
    },
    PasswordInput: {
      styles: theme => ({
        innerInput: {
          fontSize: 18,
          height: 40,
          '&::placeholder': {
            color: theme.colors.appPlaceholder,
          },
        },
        input: {
          borderRadius: 0,
          borderColor: theme.colors.appPaper,
          '&:focus-within': {
            borderColor: theme.colors.appBlack,
          },
          color: theme.colors.appBlack,
        },

        invalid: { borderColor: 'red' },
      }),
    },
    Modal: {
      styles: () => ({
        root: {
          // Mantine v6 modal doesn't set position value anymore
          // so we should manually set it to fixed in order to make
          // zIndex value apply.
          position: 'fixed',
          zIndex: 1005,
        },
        header: {
          position: 'unset',
          marginBottom: 16,
        },
        body: {
          padding: 0,
          paddingRight: 9,
        },
        inner: {
          padding: 0,
        },
      }),
    },
    Notification: {
      styles: theme => ({
        icon: {
          width: 32,
          height: 32,
          borderRadius: 32,
          marginRight: 24,
        },
        root: {
          padding: '12px 16px !important',
        },
        title: {
          fontSize: 18,
          fontFamily: 'TT Commons Medium',
          color: theme.colors.appBlack,
        },
        description: {
          fontSize: 18,
          fontFamily: 'TT Commons Regular',
          color: theme.colors.appPlaceholder,
        },
        closeButton: {
          color: theme.colors.appPlaceholder,
          '&:hover': { backgroundColor: theme.colors.appPaper },
          height: 20,
          width: 20,
          '>svg': {
            width: 20,
            height: 20,
          },
        },
      }),
    },
  },
}
