import * as Sentry from '@sentry/nextjs'
import { useQueryClient } from '@tanstack/react-query'
import { useUser } from 'next-firebase-auth'

import { useAnalytics } from '~/context/SegmentProvider'

export const useAuth = () => {
  const authUser = useUser()
  const thatchSegment = useAnalytics()
  const queryClient = useQueryClient()

  const signOut = async (callback?: () => void) => {
    await authUser.signOut()
    thatchSegment.reset()
    Sentry.setUser(null)
    queryClient.clear()
    if (callback) {
      callback()
    }
  }

  return { ...authUser, signOut }
}
